import React, { useCallback, useContext, useState } from 'react';
import { Button, CustomNotification, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import IncentiveFilter from './IncentivesFilter.tsx/IncentivesFilter';
import useFilter from 'hooks/useFilter';
import useApi from 'hooks/useApi';
import { getIncentivesList, getDriversList } from 'services/message.service';
import { Avatar, Skeleton, Tooltip } from 'antd';
import style from './Incentives.module.scss';
import useMount from 'hooks/useMount';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';
import classnames from 'classnames';
type OffsetType = { defaultOffset: string; previousOffset: any };

const IncentivePage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken') || '{}');
    const [offset, setOffset] = useState<OffsetType>({
        defaultOffset: '',
        previousOffset: [],
    });
    const [isUpdateOffset, setIsUpdateOffset] = useState(true);
    const [count, setCount] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) || 
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }

    const { modifyFilters, requestState } = useFilter({
        search_by: '',
        search_key: '',
        user_id: '',
        after: offset.defaultOffset,
        limit: 10,
    });
    const [hasMore, setHasMore] = useState(false);

    const { request } = useApi({
        api: getIncentivesList,
    });

    useMount(() => {
        fetchIncentives(requestState);
    });

    const { request: getDriversRequest, loading: getDriversLoading } = useApi({
        api: getDriversList,
    });

    const fetchIncentives = useCallback(
        async (requestState: {}, isNext?: boolean) => {
            try {
                setLoading(true);
                const result = await request(requestState);
                if (!result.error) {
                    let d = result.data || {};
                    d.incentives?.length > 0
                        ? setShowPagination(true)
                        : setShowPagination(false);
                    let incentive = d.incentives?.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
    
                    const uniqueDriverIds = d.incentives
                        .map((c: any) => c.driver_id)
                        .join(',');
                    const drivers = await getDriversRequest({
                        page_size: 100,
                        page: 1,
                        search_by: '',
                        sort_key: '',
                        sort_by: '',
                        driver_ids: uniqueDriverIds,
                    });
                    const incentives = d.incentives?.map((c: any) => {
                        const driver = drivers?.data?.data?.drivers?.find(
                            (d: any) => d.id === c.driver_id
                        );
    
                        if (driver) {
                            c.public_id = driver?.public_id;
                            c.name = (driver?.first_name + ' ' + driver?.last_name).trim();
                            c.avatar_url = driver?.avatar_url;
                        } else {
                            c.name = 'Driver Not Found';
                        }
    
                        return c;
                    });
                    let previousOffsetTemp: Array<string>;
                    let defaultOffsetTemp: string;
                    // always change previousOffset value before changing defaultOffset value
                    if (isNext == null || isNext == undefined) {
                        previousOffsetTemp = [...offset.previousOffset, offset.defaultOffset];
                        setOffset({
                            defaultOffset: incentive.slice(-1)?.[0].ledger_id,
                            previousOffset: previousOffsetTemp,
                        });
                    } else {
                        if (isNext) {
                            // Push defualtOffset into previousOffset array
                            previousOffsetTemp = [
                                ...offset.previousOffset,
                                offset.defaultOffset,
                            ];
                            defaultOffsetTemp = incentive.slice(-1)?.[0].ledger_id;
                            setOffset({
                                defaultOffset: defaultOffsetTemp,
                                previousOffset: previousOffsetTemp,
                            });
                        } else {
                            // Remove latest previousOffset element from previousOffset array
                            previousOffsetTemp = [...offset.previousOffset].slice(
                                0,
                                offset.previousOffset.length - 1
                            );
                            defaultOffsetTemp =
                                offset.previousOffset[offset.previousOffset.length - 1];
                            setOffset({
                                defaultOffset: defaultOffsetTemp,
                                previousOffset: previousOffsetTemp,
                            });
                        }
                    }
                    setData(incentives);
                    setHasMore(d.has_more);
                    setLoading(false);
                } else {
                    setData([]);
                    setLoading(false);
                    CustomNotification({
                        type: 'error',
                        message: 'Error',
                        description: result.error.message,
                    });
                }
            } catch (error) {
                setData([]);
                throw error;
            }
        },
        [request, offset, getDriversRequest]
    );

    const onFilterSubmit = useCallback(
        (filter: any) => {
            modifyFilters({
                user_id: filter.driverId,
                installment_type: filter.installmentType,
            });

            fetchIncentives({
                ...requestState,
                page: 1,
                user_id: filter.driverId,
            });
        },
        [fetchIncentives, modifyFilters, requestState]
    );

    const navigate = useNavigate();

    const columns = [
        {
            title: 'Incentive ID',
            render: (incentive: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{incentive.ledger_id}</Text>
                </div>
            ),
        },
        {
            title: 'Driver ID',
            key: 'public_id',
            render: (incentive: any) =>
                getDriversLoading ? (
                    <Skeleton paragraph={false} />
                ) : (
                    <div className="flex items-center">
                        <a
                            href={`/drivers/${incentive.driver_id}`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/drivers/${incentive.driver_id}/overview`);
                            }}
                        >
                            {incentive.public_id}
                        </a>
                    </div>
                ),
        },
        {
            title: 'Driver',
            key: 'name',
            render: (incentive: any) =>
                getDriversLoading ? (
                    <Skeleton paragraph={false} />
                ) : (
                    <div className="flex items-center gap-0">
                        <div className="flex items-center gap-2">
                            <Avatar
                                src={incentive.avatar_url}
                                size="small"
                                className="mr-2"
                            />

                            <span>{`${incentive.name}`}</span>
                        </div>
                    </div>
                ),
        },
        {
            title: 'Total Amount',
            key: 'amount',
            render: (incentive: any) => (
                <div className="w-full text-right font-semibold">
                    PHP {incentive.amount.toFixed(2)}
                </div>
            ),
        },
        {
            title: 'Campaign ID',
            key: 'incentive_campaign_id',
            render: (incentive: any) => (
                <div className="max-w-[200px]">
                    <Text>{incentive.incentive_campaign_id || "-"}</Text>
                </div>
            ),
        },
        {
            title: 'Awarded At',
            key: 'awarded_at',
            render: (incentive: any) => (
                <div>
                    <span className="underline decoration-dotted cursor-pointer">
                        {moment
                        .utc(incentive.awarded_at).add(8,'hour')
                        .format('MMMM DD, YYYY hh:mm:ss A')}
                    </span>
                </div>
            ),
        },
        {
            title: 'Awarded By',
            key: 'awarded_by',
            render: (incentive: any) => (
                <div>{incentive.awarded_by ? incentive.awarded_by : '-'}</div>
            ),
        },
    ];

    const previouspage = () => {
        setIsUpdateOffset(false);
        fetchIncentives(
            {
                ...requestState,
                after: (offset.previousOffset[offset.previousOffset.length - 2] < 1) ? 0 : offset.previousOffset[offset.previousOffset.length - 2],
            },
            false
        );
    };

    const nextpage = () => {
        setCount(count + 1);
        if (!isUpdateOffset) {
            setCount(count - 1);
            setIsUpdateOffset(true);
        }

        fetchIncentives({ ...requestState, after: offset.defaultOffset }, true);
    };

    return (
        <ListLayout
            title={'Incentives'}
            searchComponent={
                <IncentiveFilter onFilterSubmit={onFilterSubmit} loading={loading} />
            }
            actionComponent={
                hasActionAccess(PagesEnum.INCENTIVES) && <div className="flex justify-end">
                    <Link to="upload">
                        <Button type="primary">Create New Incentives</Button>
                    </Link>
                </div>
            }
        >
            <div>
                <Table
                    loading={loading}
                    columns={columns}
                    pagination={false}
                    dataSource={data}
                />
            </div>
            {showPagination && !loading && !getDriversLoading && data?.length > 0 && (
                <div className="flex">
                    <div className="flex-1"></div>
                    <div className="flex gap-2">
                        <Button
                            onClick={previouspage}
                            disabled={offset.previousOffset.length < 2}
                        >
                            Previous
                        </Button>
                        <Button onClick={nextpage} disabled={!hasMore}>
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </ListLayout>
    );
};

export default IncentivePage;
