import React, { Suspense, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LoginPage, DashboardLayout, Card } from 'components';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticationGuard } from 'auth0/AuthenticationGuard';
import { Skeleton, Spin, notification } from 'antd';
import {
    DashboardPage,
    DriversPage,
    CustomersPage,
    CustomerDetailedPage,
    DriverDetailedPage,
    TripsPage,
    TransactionsPage,
    OperatorsPage,
    CallbackPage,
    NotFoundPage,
    SettingsPage,
    TransactionDetailedPage,
    TripDetailedPage,
    FleetManagementPage,
    InstallmentPage,
    IncentivesPage,
    UploadIncentivePage,
    UploadInstallmentPage,
} from 'components/pages';

import useMount from 'hooks/useMount';
import { setAuthInterceptor } from 'interceptors/request';
import VouchersPage from 'components/pages/Vouchers/VouchersPage';
import ViewFleetsPage from 'components/pages/Settings/FleetManagement/ViewFleetsPage';
import UserManagementAuditLogsPage from 'components/pages/Logs/AuditLogs/UserManagementAuditLogsPage';
import LogsPage from 'components/pages/Logs/LogsPage';
import FareConfigPage from 'components/pages/Settings/FareConfig/FareConfigPage';
// import SurchargeConfigPage from 'components/pages/Settings/SurchargeConfig/SurchargeConfigPage';
import FinanceAuditLogsPage from 'components/pages/Logs/AuditLogs/FinanceAuditLogsPage';
import TripAuditLogsPage from 'components/pages/Logs/AuditLogs/TripAuditLogsPage';
import BulkTopUpsDeductions from 'components/pages/BulkTopUpsDeductions/BulkTopUpsDeductionsPage';
import LedgerAccounts from 'components/pages/LedgerAccounts/LedgerAccounts';
import PlaygroundPage from 'components/pages/Playground/PlaygroundPage';
import VipCustomersPage from 'components/pages/VipCustomers/VipCustomersPage';
import VipCustomerDetailed from 'components/pages/VipCustomers/VipCustomerDetailed';
import BulkMotorcycleUpdatePage from 'components/pages/Settings/BulkMotorcycleUpdate/BulkMotorcycleUpdatePage';
import { AuthUserContext } from 'components/context/AuthUserContext';
import UploadActivateDrivers from 'components/pages/Drivers/UploadActivateDrivers';
import GCashTransaction from 'components/pages/GCashTransactions/GCashTransaction';
import CustomerTransactionPage from 'components/pages/GCashTransactions/CustomerTransaction';
import ZonePage from 'components/pages/Zones/ZonePage';
import ZoneDetailPage from 'components/pages/Zones/ZoneDetailPage';
import EditZonePage from 'components/pages/Zones/EditZonePage';

const protectedRoutes = [
    { path: '/', component: DashboardPage },
    {
        path: '/drivers',
        component: DriversPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'MARKETING',
            'DREX',
            'ONBOARDING',
            'EXPANSION',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'VISMIN_ONBOARDING',
            'ONBOARDING_MANAGER',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/drivers/:id/overview',
        component: DriverDetailedPage,
        page: 'overview',
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'MARKETING',
            'DREX',
            'ONBOARDING',
            'EXPANSION',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'VISMIN_ONBOARDING',
            'ONBOARDING_MANAGER',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/drivers/:id/trip-history',
        component: DriverDetailedPage,
        page: 'trip-history',
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'MARKETING',
            'DREX',
            'ONBOARDING',
            'EXPANSION',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'VISMIN_ONBOARDING',
            'ONBOARDING_MANAGER',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/drivers/:id/wallet',
        component: DriverDetailedPage,
        page: 'wallet',
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'MARKETING',
            'DREX',
            'ONBOARDING',
            'EXPANSION',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'VISMIN_ONBOARDING',
            'ONBOARDING_MANAGER',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/drivers/:id/vehicles',
        component: DriverDetailedPage,
        page: 'vehicles',
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'MARKETING',
            'DREX',
            'ONBOARDING',
            'EXPANSION',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'VISMIN_ONBOARDING',
            'ONBOARDING_MANAGER',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/customers',
        component: CustomersPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'EXPANSION',
            'PRODUCT_OWNER',
            'OPERATOR',
            'ONBOARDING_MANAGER',
            'MARKETING',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/customers/:id/overview',
        component: CustomerDetailedPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'ONBOARDING_MANAGER',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'EXPANSION',
            'PRODUCT_OWNER',
            'OPERATOR',
            'VISMIN_ONBOARDING',
            'MARKETING',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/customers/:id/trip-history',
        component: CustomerDetailedPage,
        page: 'trip-history',
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'ONBOARDING_MANAGER',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'EXPANSION',
            'PRODUCT_OWNER',
            'VISMIN_ONBOARDING',
            'OPERATOR',
            'MARKETING',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    { path: '/vip-customers', component: VipCustomersPage },
    { path: '/vip-customers/:id', component: VipCustomerDetailed },
    {
        path: '/activate-drivers/upload',
        component: UploadActivateDrivers,
        restrictTo: [
            'SUPER_ADMIN',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'MARKETING',
            'ONBOARDING',
            'ONBOARDING_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/trips',
        component: TripsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'ONBOARDING_MANAGER',
            'VISMIN_ONBOARDING',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'DREX',
            'MARKETING',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/trips/:id',
        component: TripDetailedPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'ONBOARDING_MANAGER',
            'VISMIN_ONBOARDING',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'DREX',
            'MARKETING',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/transactions',
        component: TransactionsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'ONBOARDING_MANAGER',
            'VISMIN_ONBOARDING',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/zones',
        component: ZonePage,
        restrictTo: ['SUPER_ADMIN', 'SERVICE_EXCELLENT', 'VISMIN_ONBOARDING', 'FINANCE_MANAGER', 'CITY_MANAGER'],
    },
    {
        path: '/zones/overview/:id',
        component: ZoneDetailPage,
        restrictTo: ['SUPER_ADMIN', 'SERVICE_EXCELLENT', 'VISMIN_ONBOARDING', 'FINANCE_MANAGER','CITY_MANAGER'],
    },
    {
        path: '/zones/edit/:layerId/:zoneId',
        component: EditZonePage,
        restrictTo: ['SUPER_ADMIN', 'SERVICE_EXCELLENT', 'VISMIN_ONBOARDING', 'FINANCE_MANAGER', 'CITY_MANAGER'],
    },
    {
        path: '/installments/upload',
        component: UploadInstallmentPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'VISMIN_ONBOARDING',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'ONBOARDING_MANAGER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/incentives',
        component: IncentivesPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'VISMIN_ONBOARDING',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'ONBOARDING_MANAGER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/incentives/upload',
        component: UploadIncentivePage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'ONBOARDING_MANAGER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/operators',
        component: OperatorsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'SERVICE_EXCELLENT',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/settings',
        component: SettingsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'SERVICE_EXCELLENT',
            'OPS_STRATEGY_2',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/settings/fleet-management',
        component: FleetManagementPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'SERVICE_EXCELLENT',
            'OPS_STRATEGY_2',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/transactions',
        component: TransactionsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'ONBOARDING_MANAGER',
            'VISMIN_ONBOARDING',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/transactions/:id',
        component: TransactionDetailedPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'ONBOARDING_MANAGER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/gcash-transaction',
        component: GCashTransaction,
        restrictTo: [
            'FINANCE',
            'SUPER_ADMIN',
            'OPERATOR',
            'PRODUCT_OWNER',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_SUPPORT',
            'SERVICE_EXCELLENT_AGENT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/customer-transactions/gcash-transaction',
        component: CustomerTransactionPage,
        restrictTo: [
            'FINANCE',
            'SUPER_ADMIN',
            'OPERATOR',
            'PRODUCT_OWNER',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_SUPPORT',
            'SERVICE_EXCELLENT_AGENT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/installments',
        component: InstallmentPage,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'VISMIN_ONBOARDING',
            'OPS_STRATEGY_2',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'ONBOARDING_MANAGER',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/settings/fleet-management/:id',
        component: ViewFleetsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'SERVICE_EXCELLENT',
            'OPS_STRATEGY_2',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/settings/fare-config',
        component: FareConfigPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'SERVICE_EXCELLENT',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/settings/bulk-motorcycle-update',
        component: BulkMotorcycleUpdatePage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'ONBOARDING_MANAGER',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    // {
    //     path: '/settings/surcharge-config',
    //     component: SurchargeConfigPage,
    //     restrictTo: ['SUPER_ADMIN'],
    // },
    {
        path: '/vouchers',
        component: VouchersPage,
        restrictTo: ['SUPER_ADMIN', 'SERVICE_EXCELLENT_SUPPORT', 'SERVICE_EXCELLENT', 'FINANCE', 'FINANCE_MANAGER'],
    },
    {
        path: '/logs',
        component: LogsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'ONBOARDING_MANAGER',
            'SERVICE_EXCELLENT',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'FINANCE',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/logs/audit-logs/user-management',
        component: UserManagementAuditLogsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'ONBOARDING_MANAGER',
            'SERVICE_EXCELLENT',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/logs/audit-logs/finance',
        component: FinanceAuditLogsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'ONBOARDING_MANAGER',
            'SERVICE_EXCELLENT',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/logs/audit-logs/trips',
        component: TripAuditLogsPage,
        restrictTo: [
            'SUPER_ADMIN',
            'IT_OPS',
            'ONBOARDING_MANAGER',
            'SERVICE_EXCELLENT',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'OPERATOR',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/bulk-top-up-deductions',
        component: BulkTopUpsDeductions,
        restrictTo: [
            'SUPER_ADMIN',
            'OPS_STRATEGY_1',
            'OPS_STRATEGY_2',
            'VISMIN_ONBOARDING',
            'SERVICE_EXCELLENT',
            'SERVICE_EXCELLENT_AGENT',
            'PRODUCT_OWNER',
            'ONBOARDING_MANAGER',
            'OPERATOR',
            'SERVICE_EXCELLENT_SUPPORT',
            'FINANCE',
            'FINANCE_MANAGER',
            'CITY_MANAGER'
        ],
    },
    {
        path: '/ledger-accounts',
        component: LedgerAccounts,
        restrictTo: ['FINANCE', 'FINANCE_MANAGER'],
    },
    // {
    //     path: '/demand-hotspots',
    //     component: DemandHotspotsPage,
    //     restrictTo: ['SUPER_ADMIN'],
    // },
    {
        path: '/playground',
        component: PlaygroundPage,
        restrictTo: ['SUPER_ADMIN'],
    },
];

export const AppRoutes = () => {
    const [isProcessing, setIsProcessing] = React.useState(false);
    const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const [claims, setClaims] = React.useState<any>(null);
    const [role, setRole] = useState('test');
    const location = useLocation();

    useMount(() => {
        setIsProcessing(true);
        const setInterceptor = async () => {
            getAccessTokenSilently()
                .then(accessToken => {
                    if (accessToken) {
                        setAuthInterceptor(accessToken);
                        console.log(accessToken);

                        getIdTokenClaims().then(claims => {
                            setClaims(claims);
                            setRole(claims?.user_role);
                        });
                    }
                    setIsProcessing(false);
                })
                .catch(e => {
                    if (e.error !== 'login_required') {
                        notification.error({
                            message: 'Connection Error',
                            description:
                                'There seems to be a problem with your internet connection. You have been logged-out automatically for security.',
                            duration: 3600,
                        });
                    }
                    setIsProcessing(false);
                });
        };
        setInterceptor();
    });

    if (isLoading || isProcessing) {
        return (
            <DashboardLayout loading={true}>
                <div className="flex flex-col justify-center items-center">
                    <Card className="w-full">
                        <Skeleton active loading />
                        <Skeleton active loading />
                        <Skeleton active loading />
                    </Card>
                </div>
            </DashboardLayout>
        );
    }
    const isDashboardLayoutExcluded =
        location.pathname.startsWith('/zones/overview') || location.pathname.startsWith('/zones/edit');
    return (
        <Suspense fallback={<Spin />}>
            {!isAuthenticated || !claims ? (
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/callback" element={<CallbackPage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            ) : (
                <AuthUserContext.Provider value={role}>
                    {!isDashboardLayoutExcluded ? (
                        <DashboardLayout loading={isLoading}>
                            <Routes>
                                {protectedRoutes
                                    .filter(({ restrictTo }) => {
                                        if (!restrictTo) return true;
                                        return restrictTo.includes(claims?.user_role);
                                    })
                                    .map(({ path, component: Component, ...rest }) => (
                                        <Route
                                            key={path}
                                            path={path}
                                            element={<AuthenticationGuard component={Component} {...rest} />}
                                        />
                                    ))}
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </DashboardLayout>
                    ) : (
                        <Routes>
                            {protectedRoutes
                                .filter(({ restrictTo }) => {
                                    if (!restrictTo) return true;
                                    return restrictTo.includes(claims?.user_role);
                                })
                                .map(({ path, component: Component, ...rest }) => (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={<AuthenticationGuard component={Component} {...rest} />}
                                    />
                                ))}
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    )}
                </AuthUserContext.Provider>
            )}
        </Suspense>
    );
};
