import { Card, Text } from 'components/basic';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolOutlined, AuditOutlined } from '@ant-design/icons';
import { Alert, Tag } from 'antd';

const LogsPage = () => {
    const navigate = useNavigate();
    const authEnv = process.env.REACT_APP_AUTH0_ENV;

    return (
        <div className="flex flex-col gap-4">
            <div className="grid grid-cols-4 gap-4">
                <Card
                    className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => navigate('/logs/audit-logs/user-management')}
                >
                    <div className="flex gap-2 justify-between items-center">
                        <Text type="title">User Management</Text>
                        <Tag color="geekblue">Audit Log</Tag>
                    </div>

                    <Text type="label" className="mt-md">
                        View audit logs that are related to Driver Profiles, Customer
                        Profiles, Devices, and Fleet Management.
                    </Text>
                </Card>

                <Card
                    className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => navigate('/logs/audit-logs/finance')}
                >
                    <div className="flex gap-2 justify-between items-center">
                        <Text type="title">Finance</Text>
                        <Tag color="geekblue">Audit Log</Tag>
                    </div>

                    <Text type="label" className="mt-md">
                        View audit logs that are related to Wallet Transactions,
                        Installments, Incentives, and Vouchers.
                    </Text>
                </Card>

                <Card className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => navigate('/logs/audit-logs/trips')}
                >
                    <div className="flex gap-2 justify-between items-center">
                        <Text type="title">Trips</Text>
                        <Tag color="geekblue">Audit Log</Tag>
                    </div>

                    <Text type="label" className="mt-md">
                        Trips cancelled by operators.
                    </Text>

                    <Alert type="info" message="This feature is coming soon." showIcon />
                </Card>

                <Card className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition">
                    <div className="flex gap-2 justify-between items-center">
                        <Text type="title">Pricing</Text>
                        <Tag color="geekblue">Audit Log</Tag>
                    </div>

                    <Text type="label" className="mt-md">
                        Pricing configs viewing and editing.
                    </Text>

                    <Alert type="info" message="This feature is coming soon." showIcon />
                </Card>
            </div>
            <div className="grid grid-cols-4 gap-4">
                <Card
                    className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => {
                        // open url in new tab
                        window.open(
                            `https://manage.auth0.com/dashboard/jp/${authEnv}/logs`,
                            '_blank'
                        );
                    }}
                >
                    <div className="flex gap-2 justify-between items-center">
                        <Text type="title">Authentication Logs</Text>
                        <Tag>Security</Tag>
                    </div>

                    <Text type="label" className="mt-md">
                        View login history of all users in the system. This includes login
                        history in AAP, CAX, and DAX apps.
                    </Text>

                    <Alert
                        type="warning"
                        message="This requires a user account in our Auth0 platform. If you do not have access yet, please request it from IT team."
                    />
                </Card>
            </div>
            <div className="grid grid-cols-4 gap-4"></div>
        </div>
    );
};

export default LogsPage;
