import { Button, Card, Collapse, Spin, Tag, Tooltip } from 'antd';
import { Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useMemo } from 'react';
import { approveDevice } from 'services/message.service';
import classnames from 'clsx';
import { AppleOutlined, AndroidOutlined, DownCircleOutlined } from '@ant-design/icons';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
interface Device {
    id: string;
    device_id: string;
    device_name: string;
    device_os: string;
    device_os_version: string;
    device_model: string;
    active: boolean;
}

interface DeviceInfoCardProps {
    deviceData: Device[] | undefined;
    refreshList: () => void;
    loading: boolean | undefined;
    hasActivate?: boolean;
}

const DeviceInfoCard: React.FC<DeviceInfoCardProps> = ({
    deviceData,
    refreshList,
    loading,
    hasActivate = false,
}) => {
    const { request: requestApproveDevice, loading: approving } = useApi({
        api: approveDevice,
    });

    const handleActivateDevice = async (deviceId: string) => {
        try {
            await requestApproveDevice({ deviceId });
            refreshList();
        } catch (error) {
            throw error;
        }
    };

    const sortedDeviceData = useMemo(() => {
        return deviceData?.slice().sort((a, b) => a.id.localeCompare(b.id));
    }, [deviceData]);

    const renderedDeviceData = useMemo(() => {
        return (
            sortedDeviceData &&
            sortedDeviceData.map(
                ({
                    id,
                    device_id,
                    device_name,
                    device_os,
                    device_os_version,
                    device_model,
                    active,
                }) => (
                    <div
                        className={classnames(
                            'rounded p-sm border border-solid border-slate-200 bg-white'
                        )}
                        key={id}
                    >
                        <div key={id}>
                            <div className="flex items-center gap-2"></div>

                            <Collapse bordered={false} ghost expandIconPosition="right">
                                <CollapsePanel
                                    header={
                                        <div className="flex items-center flex-1">
                                            <div className="flex-1">
                                                <div className="flex items-center gap-2">
                                                    {device_name || 'No Device Name'}
                                                    {active && (
                                                        <Tag color="green">ACTIVE</Tag>
                                                    )}
                                                </div>
                                            </div>
                                            {device_os === 'ios' && (
                                                <AppleOutlined className="text-lg" />
                                            )}
                                            {device_os === 'android' && (
                                                <AndroidOutlined className="text-lg" />
                                            )}
                                        </div>
                                    }
                                    key="1"
                                >
                                    <div className="flex gap-2 flex-col">
                                        <div>
                                            {/* <p className="m-0">
                                                <Tooltip title="Device ID">
                                                    <span className="text-xs font-semibold underline decoration-dotted cursor-pointer">
                                                        {device_id}
                                                    </span>
                                                </Tooltip>
                                            </p> */}
                                            <p className="m-0">
                                                <Tooltip title="Device Model">
                                                    <span className="mb-0 underline decoration-dotted cursor-pointer">
                                                        {device_model}
                                                    </span>
                                                </Tooltip>
                                            </p>

                                            <p className="m-0">
                                                <Tooltip title="Device OS and OS Version">
                                                    <span className="label underline decoration-dotted cursor-pointer">
                                                        {`${device_os} - ${device_os_version}`}
                                                    </span>
                                                </Tooltip>
                                            </p>
                                        </div>
                                        <Tooltip title="Internal ID">
                                            <div>
                                                <Text type="uuid">{id}</Text>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </CollapsePanel>
                            </Collapse>

                            {/* {hasActivate && !active && (
                                <Button
                                    loading={approving}
                                    type="ghost"
                                    onClick={() => handleActivateDevice(id)}
                                    className="mt-4 w-full"
                                >
                                    Activate Phone
                                </Button>
                            )} */}
                        </div>
                    </div>
                )
            )
        );
        //eslint-disable-next-line
    }, [deviceData, approving, sortedDeviceData]);

    return (
        <div className="bg-white rounded p-lg border border-dashed border-slate-200">
            <div className="pb-sm">
                <Text color="text-gray" className="uppercase">
                    Device Information
                </Text>
                {loading ? (
                    <Spin />
                ) : sortedDeviceData && sortedDeviceData.length > 0 ? (
                    <div className="grid grid-cols-1 gap-x-6 gap-y-4 pt-md">
                        {renderedDeviceData}
                    </div>
                ) : (
                    <div className="mt-4 text-center bg-slate-50 p-8 rounded">
                        <Text type="label">No Device Registered Yet</Text>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DeviceInfoCard;
