import { Alert, Collapse, Form, Select } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { Option } from 'antd/lib/mentions';
import Modal from 'antd/lib/modal/Modal';
import { CustomNotification, Input } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { inviteOperator, updateFleetPriority } from 'services/message.service';
import { InfoCircleOutlined } from '@ant-design/icons';
interface CreateOperatorModalProps {
    modal: any;
    onSuccess?: () => void;
}

const CreateOperatorModal: React.FC<CreateOperatorModalProps> = ({
    modal,
    onSuccess,
}) => {
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);

    const handleOnClose = () => {
        modal.close();
        form.resetFields();
    };

    const { request, loading } = useApi({
        api: inviteOperator,
    });

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
    };

    const handleFormChange = (changedValues: any) => {
        setIsFormDirty(true);
    };

    const sendInvite = async (values: any) => {
        const res = await request({
            values,
        });

        const { error } = res;
        if (!error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: 'Operator invite sent.',
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: error.message,
            });
        }
    };

    const handleFormFinish = async () => {
        form.validateFields()
            .then((values) => {
                sendInvite(values);
            })
            .catch((errorInfo) => {});
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Invite an Operator"
            onOk={handleFormFinish}
            okText="Send Invite"
            okButtonProps={{
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <Form form={form} onValuesChange={handleFormChange} {...formItemLayout}>
                <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                        { required: true, message: 'Field is required' },
                        {
                            type: 'email',
                            message: 'Field must be in email format.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[{ required: true, message: 'Field is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[{ required: true, message: 'Field is required' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="user_role"
                    label="Set User Role"
                    rules={[{ required: true, message: 'Field is required' }]}
                >
                    <Select defaultValue={''}>
                        <Option value="SUPER_ADMIN">Super Admin</Option>
                        <Option value="OPERATOR">Operator</Option>
                        <Option value="FINANCE">Finance</Option>
                        <Option value="PRODUCT_OWNER">Product Owner</Option>
                        <Option value="TRANSPORT_OPS">Transport Ops</Option>
                        <Option value="IT_OPS">IT Ops</Option>
                        <Option value="QUALITY_ASSURANCE">Quality Assurance</Option>
                        <Option value="SERVICE_EXCELLENT">Service Excellence</Option>
                        <Option value="MARKETING">Marketing</Option>
                        <Option value="ONBOARDING">Transport Ops - Onboarding Team</Option>
                        <Option value="DREX">Transport Ops - DREX Team</Option>
                        <Option value="OPS_STRATEGY_1">Transport Ops - Strategy 1</Option>
                        <Option value="OPS_STRATEGY_2">Transport Ops - Strategy 2</Option>
                        <Option value="EXPANSION">Transport Ops - Expansion</Option>
                        <Option value="SERVICE_EXCELLENT_AGENT">SE Agent</Option>
                        <Option value="SERVICE_EXCELLENT_SUPPORT">SE Support</Option>
                        <Option value="VISMIN_ONBOARDING">VisMin Onboarding</Option>
                        <Option value="ONBOARDING_MANAGER">Onboarding Manager</Option>
                    </Select>
                </Form.Item>

                <div>
                    <Collapse>
                        <CollapsePanel
                            header={
                                <div className="flex items-center gap-1">
                                    <InfoCircleOutlined />
                                    <span>What do the different user roles mean?</span>
                                </div>
                            }
                            showArrow={false}
                            key="1"
                        >
                            <span className="font-semibold">Super Admins</span>
                            <p>
                                Super Admins hold full view and edit access across User
                                Management, Trip Management, and Finance. They also
                                possess exclusive rights to create operator accounts and
                                manage fleet priorities.
                            </p>

                            <span className="font-semibold">Operators</span>
                            <p>
                                Operators are granted access to view and edit all
                                functionalities in User Management, Trip Management, and
                                Finance.
                            </p>
                        </CollapsePanel>
                    </Collapse>
                </div>
            </Form>
        </Modal>
    );
};

export default CreateOperatorModal;
