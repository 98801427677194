import { Button, Card, Dropdown, Input, Select, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { getCustomerList, getDriversTripHistory, getTrip, getTripHistory } from 'services/message.service';
import TripFilter from './TripFilter';
import { Space, Tag, message, notification } from 'antd';
import { Option } from 'antd/lib/mentions';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';

interface ColumnProps {
    trip: any;
}

const TripsPage = () => {
    const navigate = useNavigate();
    const [showInitial, setShowInitial] = useState(true);
    const [data, setData] = useState([]);
    const [tripId, setTripId] = useState('');

    const { request, result, loading } = useApi({
        api: getTripHistory,
    });

    const getTrips = useCallback(
        (requestState: {}) => {
            request(requestState)
                .then(result => {
                    let records = result?.data?.history?.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    setData(records);
                })
                .catch(error => {
                    message.error(error.message);
                });
        },
        [request],
    );

    const ColumnActions: React.FC<ColumnProps> = ({ trip }) => {
        const navigate = useNavigate();

        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'View',
                            key: '1',
                            onClick: () => {
                                window.open(`/trips/${trip.trip_id}`, '_blank');
                            },
                        },
                    ],
                }}
            >
                <Button>
                    Actions
                    <DownOutlined />
                </Button>
            </Dropdown>
        );
    };

    const columns = [
        {
            title: 'Trip ID',
            key: 'trip_id',
            render: (text: string, trip: any) => (
                <Text className="w-48 truncate" type="uuid">
                    {trip?.trip_id}
                </Text>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, trip: any) => (
                <div>
                    {trip?.status == 'complete' && (
                        <Tag color="green" className="uppercase">
                            {trip.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                    {(trip?.status == 'finding_driver' ||
                        trip?.status == 'driver_otw_pickup' ||
                        trip?.status == 'driver_arrived_pickup' ||
                        trip?.status == 'driver_otw_destination' ||
                        trip?.status == 'driver_arrived_destination') && (
                        <Tag color="geekblue" className="uppercase">
                            {trip?.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                    {trip?.status?.indexOf('driver_not_found') > -1 && (
                        <Tag color="volcano" className="uppercase">
                            {trip?.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                    {trip?.status?.indexOf('canceled') > -1 && (
                        <Tag color="red" className="uppercase">
                            {trip?.status?.replaceAll('_', ' ')}
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            title: 'Pick-up Location',
            dataIndex: 'start_location',
            key: 'start_location',
            render: (text: string, trip: any) => (
                <div>
                    <span className="block">{trip?.pickup?.main}</span>
                    <span className="block text-gray-400">{trip?.pickup?.secondary}</span>
                </div>
            ),
        },
        {
            title: 'Drop-off Location',
            dataIndex: 'dropoff_location',
            key: 'dropoff_location',
            render: (text: string, trip: any) => (
                <div>
                    <span className="block">{trip?.dropoff?.main}</span>
                    <span className="block text-gray-400">{trip?.dropoff?.secondary}</span>
                </div>
            ),
        },
        {
            title: 'Fare',
            dataIndex: 'fare',
            key: 'fare',
            render: (text: string, trip: any) => (
                <p className="whitespace-nowrap m-0 text-right">
                    <span className="mr-1 text-gray-400">{trip?.price?.currency}</span>
                    <span>{trip?.price?.final?.toFixed(2) || 0}</span>
                </p>
            ),
        },
        {
            title: '',
            render: (trip: any) => <ColumnActions trip={trip} />,
        },
    ];

    const changeShowInitial = useCallback(
        (b: boolean) => {
            setShowInitial(b);
        },
        [setShowInitial],
    );

    const handleReset = useCallback(() => {
        changeShowInitial(true);
    }, [changeShowInitial]);

    const {
        request: getTripRequest,
        result: getTripResult,
        loading: getTripLoading,
    } = useApi({
        api: getTrip,
    });

    const fetchTrip = useCallback(
        async (id: string) => {
            try {
                getTripRequest({ id }).then(res => {
                    console.log(res);
                    if (!res.error) {
                        window.open(`/trips/${id}`, '_blank');
                        setTripId('');
                    } else {
                        notification.error({
                            message: 'Error',
                            description: 'Invalid Trip ID',
                            duration: 3,
                        });
                    }
                });
            } catch (error) {
                throw error;
            }
        },
        [getTripRequest],
    );

    return (
        <div className="flex flex-col">
            <div className="text-sm p-16 text-center bg-slate-50 rounded">
                <span className="mt-4 block text-gray-600">
                    To look for a trip, go to a <a href="/drivers">Driver</a> or <a href="/customers">Customer</a>'s
                    Profile and view their Trip History.
                </span>

                <span className="mt-2 block text-gray-500">
                    Alternatively, enter a Trip ID in the search bar below.
                </span>

                <div className="w-96 inline-block">
                    <Input
                        prefix={<SearchOutlined />}
                        className="rounded-full p-lg mt-4"
                        placeholder="Enter a Trip ID here to quickly view a trip..."
                        value={tripId}
                        allowClear
                        disabled={getTripLoading}
                        onChange={(e: any) => {
                            setTripId(e.target.value);
                        }}
                        onPressEnter={() => {
                            fetchTrip(tripId);
                            // window.open(`/trips/${tripId}`, '_blank');
                            // setTripId('');
                        }}
                    />
                </div>
            </div>

            {/*             
            <ListLayout title="Trips">
                <TripFilter
                    onFilterSubmit={onFilterSubmit}
                    onFilterReset={handleReset}
                    loading={loading}
                />
            </ListLayout>
            <div
                className={classnames(
                    'transition-all ease-in-out duration-100 overflow-hidden',
                    showInitial ? 'opacity-100 max-h-98 mt-4' : 'opacity-0 max-h-0'
                )}
            >
                <Card>
                    <div className="rounded relative overflow-hidden flex flex-col items-justify">
                        <div className="flex-1 items-center flex-row p-4 relative">
                            <div className="flex flex-col items-center gap-4">
                                <SearchOutlined
                                    style={{ fontSize: '28px', color: 'gainsboro' }}
                                />
                                <p className="text-xs text-gray-400 m-0 relative text-center w-56">
                                    Start your search by searching for a Driver or
                                    Customer from the filter above.
                                </p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div
                className={classnames(
                    'transition-all ease-in-out duration-300 overflow-hidden',
                    showInitial ? 'opacity-0 max-h-0' : 'opacity-100 max-h-98 mt-4'
                )}
            >
                <ListLayout>
                    <Table
                        columns={columns}
                        loading={loading}
                        dataSource={data}
                        tableLayout="auto"
                        pagination={false}
                    />
                    
                    <Button disabled={!data || data?.length < 1}>View More</Button>
                </ListLayout>
            </div> */}
        </div>
    );
};

export default TripsPage;
