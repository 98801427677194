export const motorcycleBrands = [
    { value: 'CPI', label: 'CPI' },
    { value: 'Benelli', label: 'Benelli' },
    { value: 'BYD', label: 'BYD'},
    { value: 'Changan', label: 'Changan'},
    { value: 'Chery', label: 'Chery'},
    { value: 'Euro', label: 'Euro' },
    { value: 'Ford', label: 'Ford'},
    { value: 'GAC', label: 'GAC'},
    { value: 'Geely', label: 'Geely'},
    { value: 'Honda', label: 'Honda' },
    { value: 'Hyundai', label: 'Hyundai' },
    { value: 'Isuzu', label: 'Isuzu' },
    { value: 'KIA', label: 'KIA' },
    { value: 'MG', label: 'MG' },
    { value: 'Mitsubishi', label: 'Mitsubishi' },
    { value: 'Mitsukoshi', label: 'Mitsukoshi' },
    { value: 'Microbase Motorbike', label: 'Microbase Motorbike' },
    { value: 'Nissan', label: 'Nissan' },
    { value: 'Yamaha', label: 'Yamaha' },
    { value: 'Kawasaki', label: 'Kawasaki' },
    { value: 'Suzuki', label: 'Suzuki' },
    { value: 'SYM', label: 'SYM' },
    { value: 'Kymco', label: 'Kymco' },
    { value: 'Motorstar', label: 'Motorstar' },
    { value: 'Rusi', label: 'Rusi' },
    { value: 'Racal', label: 'Racal' },
    { value: 'Haojue', label: 'Haojue' },
    { value: 'Keeway', label: 'Keeway' },
    { value: 'TVS', label: 'TVS' },
    { value: 'Tesla', label: 'Tesla' },
    { value: 'Toyota', label: 'Toyota' },
    { value: 'Motoposh', label: 'Motoposh' },
    { value: 'Skygo', label: 'Skygo' },
    { value: 'Fekon', label: 'Fekon' },
    { value: 'STARGOLD', label: 'STARGOLD' },
    { value: 'VESPA', label: 'VESPA' },
    { value: 'Lucky Star', label: 'Lucky Star' },
];

export const motorcycleModels = [
    { value: "ACB125CBTO", label: "ACB125CBTO" },
    { value: "ACH110CBTJLLL", label: "ACH110CBTJLLL" },
    { value: "ACB125CBTK", label: "ACB125CBTK" },
    { value: "ACB125CBTL", label: "ACB125CBTL" },
    { value: "ACH110C3FKH", label: "ACH110C3FKH" },
    { value: "ACH110CSHJ11", label: "ACH110CSHJ11" },
    { value: "ACH1100SFJ", label: "ACH1100SFJ" },
    { value: "ACH110CSFKJ", label: "ACH110CSFKJ" },
    { value: "ACB125CHFL", label: "ACB125CHFL" },
    { value: "CLICK 125", label: "CLICK 125" },
    { value: "WAVE 125 GELAS", label: "WAVE 125 GELAS" },
    { value: "ACB125 CBFL", label: "ACB125 CBFL" },
    { value: "ACB125CRFK", label: "ACB125CRFK" },
    { value: "AFB110MDH", label: "AFB110MDH" },
    { value: "ACB125SBFL", label: "ACB125SBFL" },
    { value: "CLICK FI-150", label: "CLICK FI-150" },
    { value: "ACH110GSFK", label: "ACH110GSFK" },
    { value: "ACH150CBTK", label: "ACH150CBTK" },
    { value: "NMAX 2DFF", label: "NMAX 2DFF" },
    { value: "WAVE 125 GILAS", label: "WAVE 125 GILAS" },
    { value: "ACH110CSF16", label: "ACH110CSF16" },
    { value: "CLICK 150I VERSION 1", label: "CLICK 150I VERSION 1" },
    { value: "CCG126WHF", label: "CCG126WHF" }
];

export const motorcycleYears = [
    { value: 2012, label: "2012" },
    { value: 2013, label: "2013" },
    { value: 2014, label: "2014" },
    { value: 2015, label: "2015" },
    { value: 2016, label: "2016" },
    { value: 2017, label: "2017" },
    { value: 2018, label: "2018" },
    { value: 2019, label: "2019" },
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" }
];

export const pistonDisplacements = [
    { value: 100, label: "100" },
    { value: 110, label: "110" },
    { value: 115, label: "115" },
    { value: 125, label: "125" },
    { value: 135, label: "135" },
    { value: 150, label: "150" },
    { value: 155, label: "155" },
    { value: 160, label: "160" },
    { value: 165, label: "165" },
    { value: 170, label: "170" },
    { value: 175, label: "175" },
    { value: 180, label: "180" }
];

export const transmissionTypes = [
    { value: "Manual", label: "Manual" },
    { value: "Automatic", label: "Automatic" },
    { value: "Semi-Automatic", label: "Semi-Automatic" }
];

export const motorcycleColors = [
    { value: 'Black', label: 'Black' },
    { value: 'White', label: 'White' },
    { value: 'Red', label: 'Red' },
    { value: 'Blue', label: 'Blue' },
    { value: 'Yellow', label: 'Yellow' },
    { value: 'Green', label: 'Green' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Silver', label: 'Silver' },
    { value: 'Gray', label: 'Gray' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Brown', label: 'Brown' },
    { value: 'Purple', label: 'Purple' },
    { value: 'Pink', label: 'Pink' }
];
