import { Alert, Collapse, Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CustomNotification, Input } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createLayer } from 'services/message.service';
interface CreateLayerModalProps {
    modal: any;
    onSuccess?: () => void;
}

const CreateLayerModal: React.FC<CreateLayerModalProps> = ({
    modal,
    onSuccess,
}) => {
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const navigate = useNavigate();

    const handleOnClose = () => {
        modal.close();
        form.resetFields();
    };

    const { request, loading } = useApi({
        api: createLayer,
    });

    const handleFormChange = (changedValues: any) => {
        setIsFormDirty(true);
    };

    const createLayers = async (values: any) => {
        const res = await request({
            values,
        });
        const { error } = res;
        if (!error) {
            navigate(`/zones/overview/${res?.data?.id}`);
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: <p><strong>{values.name} Layers created</strong>. You can now add a zone, define areas under this layer.</p>
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: error.message,
            });
        }
    };

    const handleFormFinish = async () => {
        form.validateFields()
            .then((values) => {
                createLayers(values);
            })
            .catch((errorInfo) => {});
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Create Layer"
            onOk={handleFormFinish}
            okText="Okay"
            okButtonProps={{
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <Form form={form} onValuesChange={handleFormChange}>
                <Form.Item
                    name="name"
                    label="Layer Name"
                    rules={[{ required: true, message: 'Field is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Field is required' }]}
                >
                    <Input />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default CreateLayerModal;
