import React, { useState } from 'react';
import { Select, message } from 'antd';
import Text from 'components/basic/Typography/Text';
import { Button, Input } from 'components/basic';
const { Option } = Select;

interface DriverFilterProps {
    onFilterSubmit: (values: {
        field: string;
        keyword: string;
        account_status: string;
        service_zone: string;
        service_type: string;
    }) => void;
    onFilterReset?: () => void;
    loading: boolean | undefined;
}

const DriverFilter: React.FC<DriverFilterProps> = ({ onFilterSubmit, onFilterReset, loading }) => {
    const [selectedField, setSelectedField] = useState('phone_number');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedServiceZone, setSelectedServiceZone] = useState('');
    const [selectedServiceType, setSelectedServiceType] = useState('');

    const handleFieldChange = (value: string) => {
        setSelectedField(value);
    };

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        if (selectedField === 'phone_number') {
            const isValidPhoneNumber = /^[\d+]*$/.test(inputValue);
            if (!isValidPhoneNumber) {
                // message.error('Please enter a valid phone number');
                return; // If the input value is not a number or a plus sign, return without setting the state
            } else if (inputValue.length > 13) {
                // if input is more than 13 chars
                return;
            }
        }
        setSearchKeyword(inputValue);
    };

    const handleFilterSubmit = () => {
        let kw = searchKeyword;
        if (selectedField === 'public_id') {
            kw = kw.replace(/[a-zA-Z]/g, '');
        } else if (selectedField === 'phone_number') {
            const isValidPhoneNumber = /^[\d+]*$/.test(searchKeyword);
            if (!isValidPhoneNumber) {
                message.error('Please enter a valid phone number');
                return; // If the input value is not a number or a plus sign, return without setting the state
            } else if (searchKeyword.length > 13) {
                message.error('Please enter a valid phone number');
                return; // Maximum length should be 13
            } else if (searchKeyword.length == 13 && searchKeyword.substring(0, 1) != '+') {
                message.error('Please enter a valid phone number');
                return; // If the input length is 13, format should be +639XXXXXXXXX
            }
        }
        onFilterSubmit({
            field: selectedField,
            keyword: kw,
            account_status: selectedStatus,
            service_zone: selectedServiceZone,
            service_type: selectedServiceType,
        });
    };

    const handleFilterReset = () => {
        setSelectedField('phone_number');
        setSelectedStatus('');
        setSearchKeyword('');
        setSelectedServiceZone('');
        setSelectedServiceType('');
        onFilterSubmit({
            field: 'phone_number',
            account_status: '',
            keyword: '',
            service_zone: '',
            service_type: '',
        });
    };

    return (
        <div className="flex items-end gap-2">
            <div className="flex flex-1 items-center gap-2">
                <div className="block w-full">
                    <Text type="label">Search by name, phone number, or Driver ID</Text>
                    <div className="flex gap-1">
                        <Select className="w-48" value={selectedField} onChange={handleFieldChange}>
                            <Option value="phone_number">Phone Number</Option>
                            <Option value="first_name">First Name</Option>
                            <Option value="last_name">Last Name</Option>
                            <Option value="email">Email</Option>
                            <Option value="public_id">Driver ID</Option>
                            <Option value="id">Internal ID</Option>
                            <Option value="jalopy_id">MH User ID</Option>
                            <Option value="jalopy_firebase_id">MH Firebase ID</Option>
                        </Select>
                        <Input
                            className="rounded-full p-lg"
                            value={searchKeyword}
                            onChange={handleKeywordChange}
                            placeholder="Enter search keyword..."
                            onPressEnter={handleFilterSubmit}
                        />
                    </div>
                </div>
                <div className="block">
                    <Text type="label">Select Status</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'ACTIVE', label: 'Active' }, 
                            { value: 'DEACTIVATED', label: 'Deactivated' },
                            { value: 'BANNED', label: 'Banned' },
                            { value: 'SUSPENDED', label: 'Suspended' },
                            { value: 'SHADOW_SUSPENDED', label: 'Shadow Suspended' },
                            { value: 'SHADOW_BANNED', label: 'Shadow Banned' },
                        ]}
                    />
                </div>
                <div className="block">
                    <Text type="label">Service Zone</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedServiceZone}
                        onChange={setSelectedServiceZone}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'MNL', label: 'MNL' },
                            { value: 'BCD', label: 'BCD' },
                            { value: 'BTG', label: 'BTG' },
                            { value: 'CDO', label: 'CDO' },
                            { value: 'CEB', label: 'CEB' },
                            { value: 'DVO', label: 'DVO' },
                            { value: 'ILO', label: 'ILO' },
                            { value: 'PPG', label: 'PPG' },
                        ]}
                    />
                </div>
                <div className="block">
                    <Text type="label">Service Type</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedServiceType}
                        onChange={setSelectedServiceType}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'passenger_bike', label: 'Passenger' },
                            { value: 'padala_bike', label: 'Padala' },
                            { value: 'passenger_four_seater', label: 'Passenger Four Seater' },
                            { value: 'passenger_six_seater', label: 'Passenger Six Seater' },
                        ]}
                    />
                </div>
            </div>
            <div className="flex items-center gap-1">
                <Button type="primary" ghost onClick={handleFilterSubmit} loading={loading} disabled={loading}>
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

export default DriverFilter;
