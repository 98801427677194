import React, { useCallback, useState } from 'react';
import { Select } from 'antd';
import Text from 'components/basic/Typography/Text';
import { Button, Input } from 'components/basic';
import { getLayersList } from 'services/message.service';
import useApi from 'hooks/useApi';
const { Option } = Select;

interface TransactionFilterProps {
    onFilterSubmit?: (values: { name: string, id: string}) => void;
    onFilterReset?: () => void;
    setPagination?: any;
    loading: boolean | undefined;
}

const ZoneFilter: React.FC<TransactionFilterProps> = ({
    onFilterSubmit,
    onFilterReset,
    loading,
}) => {
    // const [userId, setUserId] = useState('');
    const [selectedLayerId, setSelectedLayerId] = useState('');
    const [selectedLayerName, setSelectedLayerName] = useState('');
    const [data, setData] = useState([{ value: '', label: 'All Layers' }]);
    const [idData, setIdData] = useState([{ value: '', label: 'All Layers ID' }]);
    const { request: getLayersRequest, loading: fetchingLayers } = useApi({
        api: getLayersList,
    });

    const handleFilterSubmit = () => {
        if (onFilterSubmit) {
            onFilterSubmit({
                name: selectedLayerName,
                id: selectedLayerId
            });
        }
    };

    const handleFilterReset = () => {
        setSelectedLayerId('');
        setSelectedLayerName('');
        if (onFilterSubmit) {
            onFilterSubmit({ name: '', id: '' });
        }
        onFilterReset && onFilterReset();
    };

    const handleLayerIdChanged = useCallback(
        (value: any) => {
            setSelectedLayerId(value);
        },
        [setSelectedLayerId]
    );
    const handleLayerNameChanged = useCallback(
        (value: any) => {
            setSelectedLayerName(value);
        },
        [setSelectedLayerName]
    );

    const fetchLayers = useCallback(
        async (searchKey: '') => {
            try {
                const result = await getLayersRequest({
                    page_size: 100,
                    page: 1,
                    search_by: 'name',
                    search_key: searchKey,
                });
                let d = result.data;
                let layer = d.layers.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });
                let options = [{ value: '', label: 'All' }];
                let idOptions = [{ value: '', label: 'All' }];

                layer.forEach(function (d: any) {
                    options.push({
                        value: d.name,
                        label: d.name,
                    });
                    idOptions.push({
                        value: d.id,
                        label: d.id,
                    });
                });
                setData(options);
                setIdData(idOptions)
            } catch (error) {
                throw error;
            }
        },
        [getLayersRequest]
    );

    const handleSearchLayer = useCallback(
        (value: any) => {
            if (value != '') {
                fetchLayers(value);
            }
        },
        [fetchLayers]
    );

    return (
        <div className="flex items-end gap-2">
            <div className="flex items-center gap-2">
                <div className="block w-full">
                    <Text type="label">Layer Name</Text>
                    <div className="flex gap-1">
                        <Select
                            loading={fetchingLayers}
                            className="w-56"
                            showSearch
                            optionFilterProp="children"
                            placeholder="Select Layer Name"
                            onChange={handleLayerNameChanged}
                            onSearch={handleSearchLayer}
                            filterOption={(input, option: any) =>
                                (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={data}
                            defaultValue={data[0].value}
                            value={selectedLayerName}
                        />
                    </div>
                </div>
                <div className="block w-full">
                    <Text type="label">Layer ID</Text>
                    <div className="flex gap-1">
                        <Select
                            loading={fetchingLayers}
                            className="w-56"
                            showSearch
                            optionFilterProp="children"
                            placeholder="Select Layer ID"
                            onChange={handleLayerIdChanged}
                            onSearch={handleSearchLayer}
                            filterOption={(input, option: any) =>
                                (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={idData}
                            defaultValue={idData[0].value}
                            value={selectedLayerId}
                        />
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-1">
                <Button
                    type="primary"
                    ghost
                    onClick={handleFilterSubmit}
                    loading={loading}
                    disabled={loading}
                >
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

export default ZoneFilter;
