import { Affix, Alert, Form, Skeleton } from 'antd';
import { Button, Card, Dropdown, Select, Table, Text } from 'components/basic';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';
import { getAllFareConfig, getAllSurchargeConfig } from 'services/message.service';
import useMount from 'hooks/useMount';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import FareConfigServiceType from './FareConfigServiceType';
import CreateFareConfigModal from './CreateFareConfigModal';
import moment from 'moment';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import FareConfigSurge from './FareConfigSurge';

const FareConfigPage = () => {
    const [selectedVersion, setSelectedVersion] = useState('');
    const [selectedServiceZone, setSelectedServiceZone] = useState('MNL');
    const [selectedServiceType, setSelectedServiceType] = useState('passenger_bike');
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL)
    }
    const fareConfigModal = useModal();
    const {
        request,
        result: faresData,
        loading,
    } = useApi({
        api: getAllFareConfig,
    });
    const {
        request: requestSurge,
        result: surgeData,
        loading: loadingSurge,
    } = useApi({
        api: getAllSurchargeConfig,
    });

    useMount(() => {
        request();
        requestSurge();
    });

    const fares = useMemo(() => {
        return faresData?.data || [];
    }, [faresData]);

    const surge = useMemo(() => {
        return surgeData?.data || [];
    }, [surgeData]);

    const sortedFares = useMemo(() => {
        return fares.sort((a: any, b: any) => {
            const dateA = moment(a.updated_at);
            const dateB = moment(b.updated_at);
            if (dateA.isAfter(dateB)) {
                return -1;
            } else if (dateA.isBefore(dateB)) {
                return 1;
            } else {
                return 0;
            }
        });
    }, [fares]);

    function onlyUnique(value: any, index: any, array: any) {
        return array.indexOf(value) === index;
    }

    const versionOptions = useMemo(() => {
        const versions = fares
            ?.filter((fare: any) => fare.version_id)
            .map((fare: any) => fare.version_id)
            .filter(onlyUnique)
            .sort((a: any, b: any) => {
                return b.version_id - a.version_id;
            });

        if (versions?.length > 0 && !selectedVersion) {
            setSelectedVersion(versions[versions.length - 1]);
        }

        return versions?.map((v: any) => {
            return { value: v, label: `Version ${v}` };
        });
    }, [fares, selectedVersion]);

    const handleServiceTypeChange = (value: string) => {
        setSelectedServiceType(value);
        request();
        requestSurge();
    };

    const handleFareConfigServiceTypeFinish = (body: any) => {
        console.log('Form values from FareConfigServiceType:', body);
    };

    return (
        <Card>
            <div className="flex">
                <Link className="text-gray-500 text-sm cursor-pointer hover:text-blue" to={'/settings'}>
                    ← Back to Settings
                </Link>
            </div>
            <div className="flex items-center justify-between gap-2 mb-md w-full">
                <div className="flex flex-row gap-2">
                    <Text type="heading">Fare Config</Text>
                </div>
                {hasActionAccess(PagesEnum.GENERAL_SETTINGS) && <div className="flex gap-1">
                    <Button disabled type="primary" onClick={() => fareConfigModal.show()}>
                        Create New Fare Config
                    </Button>
                </div>}
            </div>
            <div className="flex gap-4">
                <div className="w-96">
                    <div>
                        <Text type="title" color="text-gray-600">
                            Manage Prices
                        </Text>
                        <Text type="label">Effectively manage your prices by setting up your fare configurations.</Text>
                    </div>

                    <div className="block mt-8">
                        <p className="m-0">Choose service zone:</p>
                        <Select
                            className="w-32"
                            defaultActiveFirstOption
                            value={selectedServiceZone}
                            onChange={setSelectedServiceZone}
                            options={[
                                { value: 'MNL', label: 'MNL' },
                                { value: 'BCD', label: 'BCD' },
                                { value: 'BTG', label: 'BTG' },
                                { value: 'CDO', label: 'CDO' },
                                { value: 'CEB', label: 'CEB' },
                                { value: 'DVO', label: 'DVO' },
                                { value: 'ILO', label: 'ILO' },
                                { value: 'PPG', label: 'PPG' },
                            ]}
                        />
                    </div>
                    <div className="block mt-2">
                        <p className="m-0">Choose service type:</p>
                        <Select
                            className="w-24"
                            defaultActiveFirstOption
                            value={selectedServiceType}
                            onChange={handleServiceTypeChange} 
                            options={[
                                { value: 'passenger_bike', label: 'Passenger' },
                                { value: 'padala_bike', label: 'Padala' },
                                { value: 'passenger_four_seater', label: 'Passenger Four Seater' },
                                { value: 'passenger_six_seater', label: 'Passenger Six Seater' },
                            ]}
                        />
                    </div>
                </div>
                <div className="flex-1">
                    {sortedFares
                        ?.filter((fare: any) => fare.service_type === selectedServiceType)
                        .filter((fare: any) => fare.service_zone === selectedServiceZone)
                        .map((fare: any, index: number) => {
                            return (
                                <FareConfigServiceType
                                    key={index}
                                    fare={fare}
                                    refreshList={() => request()}
                                ></FareConfigServiceType>
                            );
                        })}
                    {selectedServiceType === 'passenger_four_seater' && selectedServiceZone === 'MNL' && (
                        <FareConfigSurge
                            surge={surge}
                            fare={fares}
                            refreshList={() => requestSurge()}
                        />
                    )}
                </div>
            </div>
            <CreateFareConfigModal
                {...fareConfigModal}
                refreshList={() => request()}
                initialVersion={selectedVersion}
                onFinish={handleFareConfigServiceTypeFinish}
            />
        </Card>
    );
};

export default FareConfigPage;
