import { Text } from 'components/basic';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface DetailContentLayoutProps {
    goBackLink?: string;
    title?: string;
    children: ReactNode;
    header?: ReactNode;
    setContainer?: any;
    actionComponent?: any;
    goBackOnClick?: any;
}

const DetailContentLayout: React.FC<DetailContentLayoutProps> = ({
    goBackLink,
    goBackOnClick,
    title,
    children,
    header,
    setContainer,
    actionComponent
}) => {
    return (
        <div className="flex justify-center items-center" ref={setContainer}>
            <div className="w-full">
                {goBackLink && (
                    <div className="flex justify-between items-center">
                        <Link
                            className="text-gray-500 text-sm cursor-pointer hover:text-blue"
                            to={goBackLink}
                        >
                            ← Go Back
                        </Link>
                        {actionComponent && <div className="flex flex-row items-center gap-2">
                            {actionComponent}
                        </div>}
                    </div>
                )}
                {goBackOnClick && 
                    <div className="flex justify-between items-center">
                        <div
                            className="text-gray-500 text-sm cursor-pointer hover:text-blue"
                            onClick={goBackOnClick}
                        >
                            ← Go Back
                        </div>
                        {actionComponent && <div className="flex flex-row items-center gap-2">
                            {actionComponent}
                        </div>}
                    </div>
                }
                {header && <div>{header}</div>}
                {title && (
                    <Text size="text-lg" fontWeight="font-semibold" className="mb-0">
                        {title}
                    </Text>
                )}
                
                {children}
            </div>
        </div>
    );
};

export default DetailContentLayout;
