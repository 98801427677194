import { Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useState } from 'react';
import VouchersFilter from './VouchersFilter';
import useFilter from 'hooks/useFilter';
import useApi from 'hooks/useApi';
import { getVouchersList } from 'services/message.service';
import useMount from 'hooks/useMount';
import moment from 'moment';
import { notification } from 'antd';

const VoucherRedemptionPage = () => {
    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const { request, loading } = useApi({
        api: getVouchersList,
    });
    useMount(() => {
        fetchVouchersList({ ...requestState, after: 0 });
    });

    const fetchVouchersList = useCallback(
        async (requestState: {}, isNext?: boolean) => {
            try {
                const result = await request(requestState);
                if (!result.error) {
                    let d = result.data?.vouchers;
                    setData(d);
                    let pagination = result.data.pagination || {};
                    let p = {
                        current: pagination.page,
                        defaultCurrent: 1,
                        pageSize: pagination.page_size,
                        total: pagination.total_count,
                    };
                    setPagination(p);
                } else {
                    setData([]);
                    notification.error({
                        message: 'Error',
                        description: (
                            <div>
                                <Text>
                                    <strong>Error Code:</strong> {result.error.errorCode}
                                </Text>
                                <Text>
                                    <strong>Error Message:</strong> {result.error.message}
                                </Text>
                            </div>
                        ),
                        duration: 3,
                    });
                }
                
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        code: '',
        source: '',
        created_by: '',
        claimed_by: '',
        amount: '',
        created_at: '',
        expires_at: '',
    });

    const onFilterSubmit = async (filter: any) => {
        const query = {
            code: filter.field === 'voucher_id' ? filter.keyword : '',
            source: filter.field === 'source' ? filter.keyword : '',
            claimed_by: filter.field === 'public_id' ? filter.keyword : '',
            amount: filter.field === 'amount' ? filter.keyword : '',
            created_at: filter.created_at,
            expires_at: filter.expires_at,
        }
        modifyFilters({ ...query });
        await fetchVouchersList({
            ...requestState,
            ...query
        });
    };

    const onFilterReset = async () => {
        const query = {
            page_size: 10,
            page: 1,
            code: '',
            source: '',
            created_by: '',
            claimed_by: '',
            amount: '',
            created_at: '',
            expires_at: '',
        }
        modifyFilters({ ...query });
        await fetchVouchersList({
            ...query
        });
    }; 

    const columns = [
        {
            title: 'Voucher Code',
            key: 'code',
            render: (voucher: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{voucher.code}</Text>
                </div>
            ),
        },
        {
            title: 'Source',
            key: 'source',
            dataIndex: 'source',
        },
        {
            title: 'Amount',
            key: 'amount',
            render: (voucher: any) => (
                <div className="w-full text-right font-semibold">PHP {parseFloat(voucher.amount).toFixed(2)}</div>
            ),
        },
        {
            title: 'Created By',
            key: 'created_by',
            dataIndex: 'created_by',
        },
        {
            title: 'Created On',
            key: 'created_at',
            render: (voucher: any) => (
                <Text color="text-black-secondary">
                    {voucher?.created_at ? (
                            <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                {moment.utc(voucher.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                            </span>
                    ) : (
                        <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">-</span>
                    )}
                </Text>
            ),
        },
        {
            title: 'Redeemed By',
            key: 'claimed_by',
            render: (voucher: any) => (
                <div className="flex items-center">
                    {voucher.claimed_by ? <Text type="uuid">{voucher.claimed_by}</Text> : '-'}
                </div>
            ),
        },
        {
            title: 'Redeemed On',
            key: 'claimed_at',
            render: (voucher: any) => (
                <Text color="text-black-secondary">
                    {voucher?.claimed_at ? (
                            <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                {moment.utc(voucher.claimed_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')} 
                            </span>
                    ) : (
                        <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">-</span>
                    )}
                </Text>
            ),
        },
        {
            title: 'Expiry Date',
            key: 'expires_at',
            render: (voucher: any) => (
                <Text color="text-black-secondary">
                    {voucher?.expires_at ? (
                            <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                {moment.utc(voucher.expires_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                            </span>
                    ) : (
                        <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">-</span>
                    )}
                </Text>
            ),
        },
    ];

    const onTableChange = useCallback(
        async (pagination: any) => {
            const { current, pageSize } = pagination;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
            });
            await fetchVouchersList(requestState || {});
        },
        [fetchVouchersList, modifyFilters],
    );

    return (
        <ListLayout searchComponent={<VouchersFilter onFilterSubmit={onFilterSubmit} loading={loading} onFilterReset={onFilterReset}/>}>
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                scroll={{ x: 1000 }}
                onChange={onTableChange}
            />
        </ListLayout>
    );
};

export default VoucherRedemptionPage;
