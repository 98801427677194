import React, { useContext, useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { Dropdown } from 'components/basic';
import { CustomNotification, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import { deleteFleet, updateFleetPriority } from 'services/message.service';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { DownOutlined } from '@ant-design/icons';
import useModal from 'hooks/useModal';
import UpdateFleetModal from './UpdateFleetModal';
interface FleetFormProps {
    fleet: {
        id: string;
        fleet_priority: number;
        driver_count: number;
        fleet_name: string;
        commission: number;
        jalopy_id: number;
        fleet_description: string;
    };
    count: number;
    handleOnShowModal: (id: string) => void;
    onFormChange?: (id: string, changedValues: any) => void;
    onFormSubmit?: (id: string, values: any) => void;
    refreshList: () => void;
    claims?: any;
}

const FleetForm: React.FC<FleetFormProps> = ({
    fleet,
    onFormChange,
    count,
    refreshList,
    claims,
}) => {
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const navigate = useNavigate();
    const userRole = useContext(AuthUserContext);
    const deleteFleetModal = useModal();
    const updateFleetModal = useModal();
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL)
    }

    const { request, loading } = useApi({
        api: updateFleetPriority,
    });

    const { request: requestUpdateFleet, loading: updating } = useApi({
        api: updateFleetPriority,
    });

    const { request: requestDeleteFleet, loading: deleting } = useApi({
        api: deleteFleet,
    });

    const handleDeleteFleet = async () => {
        try {
            const res = await requestDeleteFleet({ id: fleet.id});
            if (res.status === 'success') {
                CustomNotification({
                    type: 'success',
                    message: 'Success',
                    description: 'Fleet has been successfully deleted.',
                });
                deleteFleetModal.close();
                refreshList();
            } else {
                CustomNotification({
                    type: 'error',
                    message: 'Error',
                    description: 'Unable to delete fleet. Please try again later.',
                });
            }
        } catch (e) {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: 'Unable to delete fleet. Please try again later.',
            });
        }
    };

    const handleUpdateFleet = async () => {
        try {
            const res = await requestUpdateFleet({ id: fleet.id});
            if (res.status === 'success') {
                CustomNotification({
                    type: 'success',
                    message: 'Success',
                    description: 'Fleet details has been successfully updated.',
                });
                updateFleetModal.close();
                refreshList();
            } else {
                CustomNotification({
                    type: 'error',
                    message: 'Error',
                    description: 'Unable to update fleet. Please try again later.',
                });
                updateFleetModal.close();
            }
        } catch (e) {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: 'Unable to update fleet. Please try again later.',
            });
            updateFleetModal.close();
        }
    };

    const handleFormChange = (changedValues: any) => {
        if (onFormChange) onFormChange(fleet.id, changedValues);
        setIsFormDirty(true);
    };

    const handleFormFinish = async (values: any) => {
        const { fleet_priority } = values;
        const v = {
            ...fleet,
            fleet_priority: parseFloat(fleet_priority),
        };
        const res = await request({ id: fleet.id, values: v });
        const { error } = res;
        if (!error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: 'Successfully Saved.',
            });
            refreshList();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: error.message,
            });
        }
    };

    const formatNumberWithLeadingZero = (number: number) => {
        if (number >= 0 && number < 10) {
            return `0${number}`;
        }
        return `${number}`;
    };

    return (
        <div className="border border-solid border-gray-100 rounded p-md flex items-center justify-between">
            <div className="flex items-center gap-4 max-w-[270px] w-auto ">
                <div>
                    <div className="w-10 h-10 rounded bg-gray-100 flex items-center justify-center text-md">
                        {formatNumberWithLeadingZero(count)}
                    </div>
                </div>
                <div>
                    <Text
                        className="cursor-pointer text-md break-all"
                        onClick={() => navigate(`${fleet.id}`)}
                    >
                        {fleet.fleet_name}
                    </Text>
                    {fleet.driver_count > 0 ? (
                        <Text color="text-black-lighter">
                            {`${fleet.driver_count} ${
                                fleet.driver_count > 1 ? 'Drivers' : 'Driver'
                            }`}
                        </Text>
                    ) : (
                        <Text type="label">No Driver</Text>
                    )}
                    {/* <Text type="uuid">{fleet.id}</Text> */}
                </div>
            </div>
            {claims?.user_role == 'SUPER_ADMIN' || claims?.user_role == 'OPS_STRATEGY_2' ? (
                <Form
                    form={form}
                    onValuesChange={handleFormChange}
                    onFinish={handleFormFinish}
                    layout="inline"
                    initialValues={{ fleet_priority: fleet.fleet_priority }}
                >
                    <Form.Item
                        name="fleet_priority"
                        label="Priority"
                        rules={[
                            { required: true, message: 'Field is required' },
                            {
                                type: 'number',
                                min: 0,
                                max: 9,
                                message: 'Must be between 0 and 9',
                                transform: (value) => {
                                    if (value !== undefined && value !== '') {
                                        const parsedValue = parseFloat(value);
                                        if (
                                            isNaN(parsedValue) ||
                                            value !== parsedValue.toString()
                                        ) {
                                            return NaN; // Return NaN for non-numeric input
                                        }
                                        return parsedValue;
                                    }

                                    return parseFloat(value);
                                },
                            },
                        ]}
                    >
                        {!hasActionAccess(PagesEnum.GENERAL_SETTINGS) && claims.user_role !== 'OPS_STRATEGY_2' ? 
                            <Text>{fleet.fleet_priority}</Text> : 
                        <Input disabled={fleet.fleet_name === 'Default Fleet'} 
                                readOnly={!hasActionAccess(PagesEnum.GENERAL_SETTINGS) && claims.user_role !== 'OPS_STRATEGY_2'} />}
                    </Form.Item>
                    <Form.Item>
                        {(hasActionAccess(PagesEnum.GENERAL_SETTINGS) || claims?.user_role === 'OPS_STRATEGY_2') && <Button
                            loading={loading}
                            type={
                                fleet.fleet_name === 'Default Fleet' || !isFormDirty
                                    ? 'ghost'
                                    : 'primary'
                            }
                            disabled={
                                fleet.fleet_name === 'Default Fleet' || !isFormDirty
                            }
                            htmlType="submit"
                        >
                            Update Priority
                        </Button>}
                    </Form.Item>
                    {(hasActionAccess(PagesEnum.GENERAL_SETTINGS) || claims?.user_role === 'OPS_STRATEGY_2') && <Dropdown
                        className='mr-sm'
                        menu={{
                            items: [
                                {
                                    label: 'Update',
                                    key: '1',
                                    onClick: () => updateFleetModal.show()
                                },
                                {
                                    label: <Text className='text-red-500'>Delete</Text>,
                                    key: '2',
                                    onClick: () => deleteFleetModal.show()
                                },
                            ],
                        }}>
                            <Button >
                                Actions
                                <DownOutlined />
                            </Button>
                    </Dropdown>}   
                    <Button onClick={() => navigate(`${fleet.id}`)}>Manage →</Button>
                </Form>
            ) : (
                <div className="flex items-center flex-row gap-4">
                    <span className="text-gray">Priority:</span>
                    <span>{fleet.fleet_priority}</span>
                </div>
            )}
            <Modal {...deleteFleetModal} 
                title='Delete Fleet'
                okType='danger'
                okText='Delete'
                onOk={() => handleDeleteFleet()}>
                <span>Are you sure you want to delete the fleet <strong>{fleet.fleet_name}</strong>? This action cannot be undone.</span>
            </Modal>
            <UpdateFleetModal fleet={fleet} fleetModal={updateFleetModal}/>
        </div>
    );
};

export default FleetForm;
