import React, { useState, useCallback } from 'react';
import { Button, Input } from 'components/basic';
import useApi from 'hooks/useApi';
import { getDriversList } from 'services/message.service';
import useMount from 'hooks/useMount';
import { Select } from 'antd';

interface InstallmentFilterProps {
    onFilterSubmit: (values: { driverId: string; installmentType: string }) => void;
    onFilterReset?: () => void;
    loading: boolean | undefined;
}

const InstallmentFilter: React.FC<InstallmentFilterProps> = ({
    onFilterSubmit,
    onFilterReset,
    loading,
}) => {
    const [selectedDriverId, setSelectedDriverId] = useState('');
    const [selectedInstallmentType, setSelectedInstallmentType] = useState('');
    const [data, setData] = useState([{ value: '', label: 'All Drivers' }]);

    const optionsTypes = [
        { value: '', label: 'All Installment Types' },
        { value: 'BIKER_GEAR', label: 'Biker Gear' },
        { value: 'INSURANCE', label: 'Insurance' },
        { value: 'SERVICE_FEE', label: 'Service Fee' },
    ];

    const handleFilterSubmit = useCallback(() => {
        onFilterSubmit({
            driverId: selectedDriverId,
            installmentType: selectedInstallmentType,
        });
    }, [onFilterSubmit, selectedDriverId, selectedInstallmentType]);

    const handleFilterReset = useCallback(() => {
        setSelectedInstallmentType('');
        setSelectedDriverId('');
        onFilterSubmit({ driverId: '', installmentType: '' });
    }, [setSelectedInstallmentType, setSelectedDriverId, onFilterSubmit]);

    const { request: getDriversRequest, loading: fetchingDrivers } = useApi({
        api: getDriversList,
    });

    useMount(() => {
        fetchDrivers('');
    });

    const fetchDrivers = useCallback(
        async (searchKey: '') => {
            try {
                const result = await getDriversRequest({
                    page_size: 100,
                    page: 1,
                    search_by: 'first_name',
                    search_key: searchKey,
                });
                let d = result.data.data;
                let driver = d.drivers.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });
                let options = [{ value: '', label: 'All Drivers' }];
                driver.forEach(function (d: any) {
                    options.push({
                        value: d.id,
                        label: d.first_name + ' ' + d.last_name,
                    });
                });
                setData(options);
            } catch (error) {
                throw error;
            }
        },
        [getDriversRequest]
    );

    const handleDriverIdChanged = useCallback(
        (value: any) => {
            setSelectedDriverId(value);
        },
        [setSelectedDriverId]
    );

    const handleInstallmentTypeChanged = useCallback(
        (value: any) => {
            setSelectedInstallmentType(value);
        },
        [setSelectedInstallmentType]
    );

    const handleSearchDriver = useCallback(
        (value: any) => {
            if (value != '') {
                fetchDrivers(value);
            }
        },
        [fetchDrivers]
    );

    return (
        <div className="flex items-center gap-1 w-8/12">
            <Select
                loading={fetchingDrivers}
                className="w-56"
                showSearch
                optionFilterProp="children"
                placeholder="Select Driver"
                onChange={handleDriverIdChanged}
                onSearch={handleSearchDriver}
                filterOption={(input, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={data}
                defaultValue={data[0].value}
                value={selectedDriverId}
            />
            <Select
                className="w-56"
                options={optionsTypes}
                placeholder="Select Installment Type"
                onChange={handleInstallmentTypeChanged}
                defaultValue={''}
                value={selectedInstallmentType}
            />
            <div className="flex items-center gap-1">
                <Button
                    type="primary"
                    ghost
                    onClick={handleFilterSubmit}
                    loading={loading}
                    disabled={loading}
                >
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Reset
                </Button>
            </div>
        </div>
    );
};

export default InstallmentFilter;
