import { Form, Modal, notification } from 'antd';
import { Input, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { sendEreceipt } from 'services/message.service';
import { validateEmail } from 'utils';

const SendEReceiptModal = ({ eReceiptModal } : { eReceiptModal: any}) => {
    const [email, setEmail] = useState(eReceiptModal.email);
    const [error, setError] = useState(false);

    const { request } = useApi({
        api: sendEreceipt
    });

    const handleSubmit = async () => {
        const emailValue = eReceiptModal.email === '' || eReceiptModal.email === undefined ? email : eReceiptModal.email;
        const body = {
            email: emailValue
        }
        const res = await request({ id: eReceiptModal.id, body });
        if (res.status === 'success') {
            notification.success({
                message: 'Success',
                description: <Text>E-receipt successfully sent to <strong>{emailValue}</strong>.</Text>,
                duration: 3,
            });
            eReceiptModal.disableButton(true);
            setTimeout(() => {
                eReceiptModal.disableButton(false);
            }, 5000); 
        } else {
            notification.error({
                message: 'Something went wrong',
                description: <Text>Unable to send e-receipt to <strong>{emailValue}</strong>. Please try again later..</Text> ,
                duration: 3,
            });
        }
        setEmail('');
        eReceiptModal.close();
    }
    
    return <Modal {...eReceiptModal} title={eReceiptModal.email !== '' ? 'Send e-receipt' : 'Send e-receipt to another email'} okText='Send E-Receipt'  
        onOk={() => {
            const emailValue = eReceiptModal.email;
            if (emailValue && emailValue.trim() !== '') {
                handleSubmit();
            } else {
                const isEmailValid = validateEmail(email);
                setError(!isEmailValid);
                if (isEmailValid) {
                    handleSubmit();
                }
            }
        }}>
        {eReceiptModal.email !== '' ? 
            <Text fontWeight='font-semibold'>Are you sure you want to send the e-receipt to {eReceiptModal.email}? This action will provide them access to view their trip details.</Text> :
            <div>
                <Text fontWeight='font-semibold'>
                    Please enter the email address. This action will provide them access to view the trip details.
                </Text>
                <Form>
                </Form>
                <div className='my-sm'>
                    <Text type='label'>Email address:</Text>
                    <Input value={email} onChange={(event) =>  {
                        const value = event.target.value;
                        if (value === '') {
                            setError(false);
                        }
                        setEmail(value)}}
                    />
                    {error && <Text color='text-red-500'>Please enter valid email address.</Text>}
                </div>
            </div>
        }
    </Modal>
}

export default SendEReceiptModal;