import { Button, Dropdown, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useState } from 'react';
import useApi from 'hooks/useApi';
import { getDriversList, getTransactionsList } from 'services/message.service';
import { DownOutlined } from '@ant-design/icons';
import useMount from 'hooks/useMount';
import useFilter from 'hooks/useFilter';
import { Alert, Avatar, Skeleton, Tag, Tooltip } from 'antd';
import moment from 'moment';
import TransactionFilter from './TransactionFilter';
import { formatNumberToAmount } from 'services/number.service';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import style from './TransactionsPage.module.scss'
const TransactionsPage = () => {
    const navigate = useNavigate();
    // const dataModal = useModal();
    const [data, setData] = useState([]);
    // const [modalData, setModalData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isUpdateOffset, setIsUpdateOffset] = useState(true);
    const [count, setCount] = useState(0);
    // const [userTransactions, setUserTransactions] = useState([]);
    type OffsetType = { defaultOffset: string; previousOffset: Array<string> };

    const [offset, setOffset] = useState<OffsetType>({
        defaultOffset: '',
        previousOffset: [],
    });
    const { requestState, modifyFilters } = useFilter({
        search_by: '',
        search_key: '',
        driver_id: '',
        user_id: '',
        after: offset.defaultOffset,
        account_type: '',
        limit: 10,
    });
    const { request, loading } = useApi({
        api: getTransactionsList,
    });

    const { request: getDriversRequest, loading: getDriversLoading } = useApi({
        api: getDriversList,
    });

    useMount(() => {
        fetchTransactions({
            after: 0,
        });
    });

    const fetchTransactions = useCallback(
        async (requestState: {}, isNext?: boolean) => {
            try {
                console.log(requestState);
                const result = await request(requestState);
                let d = result.data;

                let transaction = d.transactions.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });

                const uniqueDriverIds = d.transactions.map((c: any) => c.user_id).join(',');

                const drivers = await getDriversRequest({
                    page_size: 100,
                    page: 1,
                    search_by: '',
                    sort_key: '',
                    sort_by: '',
                    driver_ids: uniqueDriverIds,
                });
                const transactions = d.transactions.map((c: any) => {
                    const driver = drivers?.data?.data?.drivers?.find((d: any) => d.id === c.user_id);

                    if (driver) {
                        c.public_id = driver?.public_id;
                        c.name = (driver?.first_name + ' ' + driver?.last_name).trim();
                        c.avatar_url = driver?.avatar_url;
                        c.driver_found = true;
                    } else {
                        c.name = c.user_id;
                        c.driver_found = false;
                    }

                    return c;
                });
                let previousOffsetTemp: Array<string>;
                let defaultOffsetTemp: string;
                // always change previousOffset value before changing defaultOffset value
                if (isNext == null || isNext == undefined) {
                    previousOffsetTemp = [...offset.previousOffset, offset.defaultOffset];
                    setOffset({
                        defaultOffset: transaction.slice(-1)?.[0].ledger_id,
                        previousOffset: previousOffsetTemp,
                    });
                } else {
                    if (isNext) {
                        // Push defualtOffset into previousOffset array
                        previousOffsetTemp = [...offset.previousOffset, offset.defaultOffset];
                        defaultOffsetTemp = transaction.slice(-1)?.[0].ledger_id;
                        setOffset({
                            defaultOffset: defaultOffsetTemp,
                            previousOffset: previousOffsetTemp,
                        });
                    } else {
                        // Remove latest previousOffset element from previousOffset array
                        previousOffsetTemp = [...offset.previousOffset].slice(0, offset.previousOffset.length - 1);
                        defaultOffsetTemp = offset.previousOffset[offset.previousOffset.length - 1];
                        setOffset({
                            defaultOffset: defaultOffsetTemp,
                            previousOffset: previousOffsetTemp,
                        });
                    }
                }
                setData(transactions);
                setHasMore(d.has_more);
            } catch (error) {
                throw error;
            }
        },
        [request, offset, getDriversRequest],
    );

    const nextpage = () => {
        setCount(count + 1);
        if (!isUpdateOffset) {
            setCount(count - 1);
            setIsUpdateOffset(true);
        }

        fetchTransactions({ ...requestState, after: offset.defaultOffset }, true);
    };

    const previouspage = () => {
        setIsUpdateOffset(false);
        fetchTransactions(
            {
                ...requestState,
                after: offset.previousOffset[offset.previousOffset.length - 2],
            },
            false,
        );
    };

    const onFilterSubmit = async ({ user_id = '', account_type = '' }) => {
        const { requestState } = modifyFilters({ user_id, account_type });
        fetchTransactions({
            after: 0,
            ...requestState,
        });
    };

    const getSourceDestinationTags = (source: string): JSX.Element | null | undefined => {
        const parts = source.split(':');
        const sourcePrefix = parts.length >= 3 ? parts.slice(0, 3).join(':') : '';

        const sourceMappings: Record<string, JSX.Element> = {
            'AKS:REVENUE:INSTALLMENT': <Tag color="volcano">Installment Deducted</Tag>,
            'AKS:FUNDS:INCENTIVE': <Tag color="purple">Incentive Awarded</Tag>,
            'AKS:VOUCHERSALES:CLEARING': <Tag color="orange">Voucher Applied</Tag>,
            'AKS:CASH:PCASH': <Tag color="green">Cashed Out</Tag>,
            'AKS:CASHLESS:CLEARING': <Tag color="blue">Paid using Card</Tag>,
            'AKS:REVENUE:FEE': <Tag color="red">Revenue Fee Deducted</Tag>,
            'AKS:FUNDS:PROMO': <Tag color="magenta">Promo Applied</Tag>,
            'AKS:FUND:MANUAL': <Tag color="cyan">Manual Adjustment</Tag>,
            'AKS:CASH:DRIVER': <Tag color="blue">Driver Cash Wallet</Tag>,
            'AKS:CREDIT:DRIVER': <Tag color="orange">Driver Credit Wallet</Tag>,
        };

        return sourceMappings[sourcePrefix] || <Tag color="blue">{source}</Tag>;
    };

    const modalColumns = [
        {
            title: 'TXN ID',
            key: 'ledger_id',
            render: (transaction: any) => <Text fontWeight="font-semibold">{transaction.ledger_id}</Text>,
        },
        {
            title: 'Source',
            key: 'source',
            render: (transaction: any) => <div>{getSourceDestinationTags(transaction.source)}</div>,
        },
        {
            title: 'Destination',
            key: 'destination',
            render: (transaction: any) => (
                <div className="flex items-center">
                    <Tooltip title={transaction.destination}>
                        <span>{getSourceDestinationTags(transaction.destination)}</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Description',
            key: 'description',
            render: (transaction: any) => (
                <div className="flex items-center">
                    <Text>{transaction.description}</Text>
                </div>
            ),
        },
        {
            title: 'Amount',
            // dataIndex: 'amount_str',
            key: 'amount',
            align: 'right',
            render: (transaction: any) => <div>{`PHP ${formatNumberToAmount(transaction?.amount)}`}</div>,
        },
        {
            title: 'Ending Balance',
            key: 'ending_balance',
            // dataIndex: 'ending_balance_str',
            align: 'right',
            render: (transaction: any) => <div>{`PHP ${formatNumberToAmount(transaction?.ending_balance)}`}</div>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Status',
            key: 'status',
            render: (transaction: any) => (
                <div className="flex">
                    {transaction.status == 'SUCCESS' ? (
                        <Tag color="green" className="uppercase">
                            Successful
                        </Tag>
                    ) : (
                        <Tag color="orange" className="uppercase">
                            {transaction.status}
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            title: 'Direction',
            dataIndex: 'direction',
            key: 'direction',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (transaction: any) => (
                <span className="underline decoration-dotted cursor-pointer">
                    {moment.utc(transaction.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                </span>
            ),
        },
    ];

    return (
        <ListLayout
            title="Wallet History"
            searchComponent={
                <TransactionFilter onFilterSubmit={onFilterSubmit} loading={loading || getDriversLoading} />
            }
        >
            <Alert
                message="For wallet transactions made before Tesseract, you can view them still in our Manager Hub."
                type="info"
                showIcon
            />
            <Table
                loading={loading || getDriversLoading}
                columns={[
                    {
                        title: 'TXN ID',
                        key: 'ledger_id',
                        render: (transaction: any) => <Text fontWeight="font-semibold">{transaction.ledger_id}</Text>,
                    },
                    {
                        title: 'Status',
                        key: 'status',
                        render: (transaction: any) => (
                            <div className="flex">
                                {transaction.status == 'SUCCESS' ? (
                                    <Tag color="green" className="uppercase">
                                        Successful
                                    </Tag>
                                ) : (
                                    <Tag color="orange" className="uppercase">
                                        {transaction.status}
                                    </Tag>
                                )}
                            </div>
                        ),
                    },
                    // {
                    //     title: 'Starting Balance',
                    //     key: 'starting_balance',
                    //     align: 'right',
                    //     render: (transaction: any) => (
                    //         <div>
                    //             {`PHP ${formatNumberToAmount(transaction?.ending_balance - transaction?.amount)}`}
                    //         </div>
                    //     ),
                    // },
                    {
                        title: 'Amount',
                        // dataIndex: 'amount_str',
                        key: 'amount',
                        align: 'right',
                        render: (transaction: any) => (
                            <div
                                className={classnames(
                                    transaction?.direction == 'CREDIT' ? 'text-green-600' : 'text-red-600',
                                )}
                            >
                                {`PHP ${formatNumberToAmount(transaction?.amount)}`}
                            </div>
                        ),
                    },
                    {
                        title: 'Ending Balance',
                        key: 'ending_balance',
                        // dataIndex: 'ending_balance_str',
                        align: 'right',
                        render: (transaction: any) => (
                            <div>{`PHP ${formatNumberToAmount(transaction?.ending_balance)}`}</div>
                        ),
                    },
                    {
                        title: 'Type',
                        // dataIndex: 'type',
                        key: 'type',
                        className: "break-words",
                        render: (transaction: any) => (
                            <div className="flex items-center">
                                <Text className='break-words'>{transaction.type}</Text>
                            </div>
                        ),
                    },
                    {
                        title: 'Description',
                        key: 'description',
                        render: (transaction: any) => (
                            <div className="flex items-center">
                                <Text>{transaction.description}</Text>
                            </div>
                        ),
                    },
                    {
                        title: 'Driver ID',
                        key: 'public_id',
                        render: (transaction: any) =>
                            getDriversLoading ? (
                                <Skeleton round active paragraph={false} />
                            ) : (
                                <div className="flex items-center">
                                    {transaction.public_id ? (
                                        <a
                                            target="_blank"
                                            href={`/drivers/${transaction.user_id}/overview`}
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     navigate(
                                        //         `/drivers/${transaction.user_id}`
                                        //     );
                                        // }}
                                        >
                                            {transaction.public_id}
                                        </a>
                                    ) : (
                                        <span className="font-semibold">-</span>
                                    )}
                                </div>
                            ),
                    },
                    {
                        title: 'Driver',
                        key: 'name',
                        render: (transaction: any) =>
                            getDriversLoading ? (
                                <Skeleton round active paragraph={false} />
                            ) : (
                                <div className="flex items-center gap-2">
                                    {transaction.driver_found && (
                                        <Avatar src={transaction.avatar_url} size="small" className="mr-2" />
                                    )}

                                    <span>{`${transaction.name}`}</span>
                                </div>
                            ),
                    },
                    {
                        title: 'Source',
                        key: 'source',
                        render: (transaction: any) => <div>{getSourceDestinationTags(transaction.source)}</div>,
                    },
                    {
                        title: 'Destination',
                        key: 'destination',
                        render: (transaction: any) => (
                            <div className="flex items-center">
                                <Tooltip title={transaction.destination}>
                                    <span>{getSourceDestinationTags(transaction.destination)}</span>
                                </Tooltip>
                            </div>
                        ),
                    },
                    {
                        title: 'Created At',
                        key: 'created_at',
                        render: (transaction: any) => (
                            <span className="underline decoration-dotted cursor-pointer">
                                {moment.utc(transaction.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                            </span>
                        ),
                    },
                    {
                        title: 'Remarks',
                        key: 'reason',
                        render: (transaction: any) => (
                            <Tooltip title={transaction?.reason}>
                                <div className={classnames(style.multilineEllipsis, "w-[80px]")}>
                                    <Text>{transaction?.reason || "-"}</Text>
                                </div>
                            </Tooltip>
                        ),
                    },
                    // {
                    //     title: 'Transaction ID',
                    //     key: 'reference_id',
                    //     render: (transaction: any) => {
                    //         if (
                    //             transaction.reference_id === '' ||
                    //             !transaction.reference_id
                    //         ) {
                    //             return <span className="text-gray">No Internal ID</span>;
                    //         }
                    //         return (
                    //             <div className="flex items-center">
                    //                 <Text type="uuid">{transaction.reference_id}</Text>
                    //             </div>
                    //         );
                    //     },
                    // },
                    // {
                    //     title: '',
                    //     render: (transaction: any) => (
                    //         <Button
                    //             onClick={() => {
                    //                 dataModal.show(viewMore(transaction.user_id));
                    //             }}
                    //         >
                    //             View Details
                    //         </Button>
                    //     ),
                    // },
                ]}
                dataSource={data}
                pagination={false}
            // onChange={onTableChange}
            />

            {!loading && !getDriversLoading && data?.length > 0 && (
                <div className="flex">
                    <div className="flex-1"></div>
                    <div className="flex gap-2">
                        <Button onClick={previouspage} disabled={offset.previousOffset.length < 2}>
                            Previous
                        </Button>
                        <Button onClick={nextpage} disabled={!hasMore}>
                            Next
                        </Button>
                    </div>
                </div>
            )}
            {/* <DataModal {...dataModal} modalColumns={modalColumns} modalData={modalData} width='100%' /> */}
        </ListLayout>
    );
};

export default TransactionsPage;
