import React from 'react';
import { Table as AntTable } from 'antd';
import type { TableProps } from 'antd/es/table';
import './Table.scss';
import LottiePlayer from 'lottie-react';
import processing from './btn-processing-blue-mc.json';
interface Props extends TableProps<any> {
    children?: React.ReactNode;
    error?: boolean;
}

const Table = (props: Props) => {
    const { error, ...restProps } = props;

    return (
        <div>
            {error ? (
                <div className="error">
                    Error fetching the list. Please try again later.
                </div>
            ) : props.loading ? (
                <div className="w-full h-56 items-center justify-center flex rounded">
                    <LottiePlayer
                        animationData={processing}
                        loop
                        autoplay
                        style={{ width: '250px', height: '50px' }}
                    />
                </div>
            ) : (
                <AntTable {...restProps} />
            )}
        </div>
    );
};

export default Table;
