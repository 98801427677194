import React from 'react';
import { Button, Dropdown, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { Text } from 'components';
import { formatNumberToAmount } from 'services/number.service';
import { DownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import style from "../../../Transactions/TransactionsPage.module.scss";
interface Transaction {
    reference_id: string;
    ledger_id: string;
    status: string;
    type: string;
    source: string;
    destination: string;
    amount: number;
    ending_balance: number;
    created_at: string;
    description: string;
}

const getStatusTag = (status: string): JSX.Element => {
    if (status === 'SUCCESS') {
        return (
            <Tag color="green" className="uppercase">
                Successful
            </Tag>
        );
    }
    return (
        <Tag color="orange" className="uppercase">
            {status}
        </Tag>
    );
};

const getTypeTag = (type: string): JSX.Element => {
    if (type === '' || !type) {
        return <span className="text-gray">-</span>;
    }
    if (type === 'incentive') {
        return <Tag color="geekblue">INCENTIVE</Tag>;
    }
    if (type === 'MANUAL') {
        return <Tag color="geekblue">MANUAL</Tag>;
    }
    return (
        <Tag color="volcano" className="uppercase">
            {type}
        </Tag>
    );
};

const getSourceDestinationTags = (source: string): JSX.Element | null | undefined => {
    const parts = source.split(':');
    const sourcePrefix = parts.length >= 3 ? parts.slice(0, 3).join(':') : '';

    const sourceMappings: Record<string, JSX.Element> = {
        'AKS:REVENUE:INSTALLMENT': <Tag color="volcano">Installment Deducted</Tag>,
        'AKS:FUNDS:INCENTIVE': <Tag color="purple">Incentive Awarded</Tag>,
        'AKS:VOUCHERSALES:CLEARING': <Tag color="orange">Voucher Applied</Tag>,
        'AKS:CASH:PCASH': <Tag color="green">Cashed Out</Tag>,
        'AKS:CASHLESS:CLEARING': <Tag color="blue">Paid using Card</Tag>,
        'AKS:REVENUE:FEE': <Tag color="red">Revenue Fee Deducted</Tag>,
        'AKS:FUNDS:PROMO': <Tag color="magenta">Promo Applied</Tag>,
        'AKS:FUND:MANUAL': <Tag color="cyan">Manual Adjustment</Tag>,
        'AKS:CASH:DRIVER': <Tag color="blue">Driver Cash Wallet</Tag>,
        'AKS:CREDIT:DRIVER': <Tag color="orange">Driver Credit Wallet</Tag>,
    };

    return sourceMappings[sourcePrefix] || <Tag color="blue">{source}</Tag>;
};

const columns = [
    {
        title: 'TXN ID',
        key: 'ledger_id',
        render: (transaction: any) => (
            <Text fontWeight="font-semibold">{transaction.ledger_id}</Text>
        ),
    },
    {
        title: 'Status',
        key: 'status',
        render: (transaction: any) => (
            <div className="flex">
                {transaction.status == 'SUCCESS' ? (
                    <Tag color="green" className="uppercase">
                        Successful
                    </Tag>
                ) : (
                    <Tag color="orange" className="uppercase">
                        {transaction.status}
                    </Tag>
                )}
            </div>
        ),
    },
    {
        title: 'Amount',
        // dataIndex: 'amount_str',
        key: 'amount',

        render: (transaction: any) => (
            <div
                className={classnames(
                    transaction?.direction == 'CREDIT' ? 'text-green-600' : 'text-red-600'
                )}
            >
                {`PHP ${formatNumberToAmount(transaction?.amount)}`}
            </div>
        ),
    },
    {
        title: 'Ending Balance',
        key: 'ending_balance',
        // dataIndex: 'ending_balance_str',

        render: (transaction: any) => (
            <div>{`PHP ${formatNumberToAmount(transaction?.ending_balance)}`}</div>
        ),
    },
    {
        title: 'Description',
        key: 'description',
        render: (transaction: any) => (
            <div className="flex items-center">
                <Text>{transaction.description}</Text>
            </div>
        ),
    },
    {
        title: 'Source',
        key: 'source',
        render: (transaction: any) => (
            <div>{getSourceDestinationTags(transaction.source)}</div>
        ),
    },
    {
        title: 'Destination',
        key: 'destination',
        render: (transaction: any) => (
            <div className="flex items-center">
                <Tooltip title={transaction.destination}>
                    <span>{getSourceDestinationTags(transaction.destination)}</span>
                </Tooltip>
            </div>
        ),
    },
    {
        title: 'Created At',
        key: 'created_at',
        render: (transaction: any) => (
            <span className="underline decoration-dotted cursor-pointer">
                {moment
                    .utc(transaction.created_at).add(8, 'hour')
                    .format('MMMM DD, YYYY hh:mm:ss A')}
            </span>
        ),
    },
    {
        title: 'Remarks',
        key: 'reason',
        render: (transaction: any) => (
            <Tooltip title={transaction?.reason}>
                <div className={classnames(style.multilineEllipsis, "w-[80px]")}>
                    <Text>{transaction?.reason || "-"}</Text>
                </div>
            </Tooltip>
        ),
    },
];

export default columns;
