import { Card, Input, Select, Table, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { generateVouchers } from 'services/message.service';
import { Alert, Button, Form, InputNumber, Tabs, notification } from 'antd';
import { Option } from 'antd/lib/mentions';
import Modal from 'antd/lib/modal/Modal';
import useModal from 'hooks/useModal';
import {json2csv} from "json-2-csv";
import {downloadCSVFile} from "../../../services/file.service";
import VoucherRedemptionPage from './VoucherRedemption/VoucherRedemptionPage';
import { AuthUserContext } from 'components/context/AuthUserContext';
import classnames from 'classnames';
type VoucherSource = 'MAYA' | 'GCASH';

interface VoucherValue {
    amount?: number;
    source?: VoucherSource;
    quantity?: number;
}

interface VoucherInputProps {
    value?: VoucherValue;
    onChange?: (value: VoucherValue) => void;
}

const VoucherInput: React.FC<VoucherInputProps> = ({ value = {}, onChange }) => {
    const [amount, setAmount] = useState("100");
    const [source, setSource] = useState<VoucherSource>('MAYA');
    const [quantity, setQuantity] = useState(1);

    const triggerChange = (changedValue: {
        amount?: number;
        source?: VoucherSource;
        quantity?: number;
    }) => {
        onChange?.({
            amount: parseInt(amount || '0', 10),
            source,
            quantity,
            ...changedValue,
        });
    };

    const onAmountChange = (e: any) => {
        const newAmount = parseInt(e || '0', 10);
        if (Number.isNaN(amount)) {
            return;
        }
        if (!('amount' in value)) {
            setAmount(newAmount.toString());
        }
        triggerChange({ amount: newAmount });
    };

    const onSourceChange = (newSource: VoucherSource) => {
        if (!('source' in value)) {
            setSource(newSource);
        }
        triggerChange({ source: newSource });
    };

    const onQuantityChange = (e: any) => {
        const newQuantity = parseInt(e || '0', 10);
        if (Number.isNaN(quantity)) {
            return;
        }
        if (!('quantity' in value)) {
            setQuantity(newQuantity);
        }
        triggerChange({ quantity: newQuantity });
    };

    return (
        <div>
            <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                <Select
                    value={value.amount || "100"}
                    onChange={onAmountChange}
                    style={{ width: 120 }}
                    className="min-w-56"
                >
                    <Option value="100">PHP 100</Option>
                    <Option value="250">PHP 250</Option>
                    <Option value="500">PHP 500</Option>
                    <Option value="1000">PHP 1000</Option>
                </Select>
            </Form.Item>

            <Form.Item label="Source" rules={[{ required: true }]} name="source">
                <Select value={value.source || source} onChange={onSourceChange}>
                    <Option value="MAYA">Maya</Option>
                    <Option value="GCASH">GCash</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                    {
                        type: 'number',
                        min: 1,
                        message: 'Quantity must be greater than 0',
                    },
                    {
                        type: 'number',
                        max: 999,
                        message: 'Quantity must be less than 999',
                    },
                    {
                        required: true,
                        message: 'This field is required.',
                    },
                ]}
            >
                <InputNumber
                    className="text-right"
                    style={{ width: '25%' }}
                    type="number"
                    min={1}
                    value={value.quantity || quantity}
                    onChange={onQuantityChange}
                />
            </Form.Item>
        </div>
    );
};

type VoucherValues = {
    amount: any;
    quantity: number;
    source: string;
};

const VouchersPage = () => {
    const [message, setMessage] = useState('');
    const [voucherValues, setVoucherValues] = useState<VoucherValues | null>(null);
    const confirmationModal = useModal();
    const [csv, setCsv] = useState('');
    const userRole = useContext(AuthUserContext);

    const {
        request: generateVoucherRequest,
        loading: generatingVouchers,
        result: generateVoucherResult,
    } = useApi({
        api: generateVouchers,
    });

    const handleGenerateVoucher = async (values: any) => {
        confirmationModal.close();
        const { amount, quantity, source } = values || {};

        const res = await generateVoucherRequest({
            amount: parseInt(amount),
            quantity: quantity,
            source: source,
        });

        const { error, fileUrl, vouchers } = res;

        if (!error) {
            setMessage("File generated and uploaded to Google storage bucket as " + fileUrl);
            const csvData = await json2csv(vouchers);
            setCsv(csvData);

            notification.success({
                message: 'Success',
                description: 'Successfully generated.',
                duration: 3,
            });
        } else {
            notification.error({
                message: 'Error',
                description: error.message,
                duration: 3,
            });
        }
    };

    const downloadCSVData = () => {
        const csvData = String(csv);
        downloadCSVFile(csvData, "vouchers_generated")
    };

    const onFinish = async (values: any) => {
        const tempValues = { ...values };
        setVoucherValues(values);
        confirmationModal.show({
            title: 'Generate Voucher',
            okText: 'Yes',
            children: (
                <div className="flex flex-col gap-2">
                    <Alert
                        message="Generated vouchers will have a validity of one year."
                        showIcon
                    />
                    <div>
                        <p>By clicking "Yes", you are about to create a new voucher.</p>
                        <p>
                            Please review the voucher details carefully. Once generated,
                            the voucher code will be issued and cannot be modified.
                        </p>
                        <p>Do you wish to proceed?</p>
                    </div>
                </div>
            ),

            onOk: () => handleGenerateVoucher(values),
        });
    };
    return (
        <div className="flex flex-col">
            <Text type="heading">
                Vouchers
            </Text>
            <Tabs
                defaultActiveKey="1"
                className="gap-2"
                tabBarGutter={50}
                items={[
                    {
                        label: (
                            <div className="flex flex-row gap-0 items-center">
                                <Text className="uppercase">Generate Voucher Codes</Text>
                            </div>
                        ),
                        key: 'voucher_generation',
                        children: <div>
                        <div className="border-dashed border-1 border border-gray-200 p-8 inline-block">
                            <Text type="title">Generate Voucher Codes</Text>
                            {message && (
                                <div className="flex flex-col flex-items gap-2">
                                    <div className="flex items-center">
                                        <Alert type="success" message={message} showIcon />
                                    </div>
                                    <div className="flex gap-2">
                                        <button
                                            type="submit"
                                            style={{
                                                border: '1px solid #D9D9D9',
                                                boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                                                cursor: "pointer",
                                            }}
                                            className="bg-inherit px-4 py-2"
                                            onClick={() => {
                                                setMessage('');
                                                setCsv('')
                                            }}
                                        >
                                            Generate Another
                                        </button>
                                        <button
                                            type="submit"
                                            style={{
                                                border: '1px solid #D9D9D9',
                                                boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                                                cursor: "pointer",
                                            }}
                                            className="bg-inherit px-4 py-2"
                                            onClick={() => downloadCSVData()}
                                            id="text-button"
                                        >
                                            Download Vouchers
                                        </button>
                                    </div>
                                    <div>
        
                                    </div>
                                </div>
                            )}
                            <Form
                                style={{ maxWidth: 500 }}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 14 }}
                                layout="horizontal"
                                onFinish={onFinish}
                                initialValues={{
                                    amount: '100',
                                    source: 'MAYA',
                                    quantity: 1,
                                }}
                                disabled={generatingVouchers}
                            >
                                {!message && (
                                    <div className="flex flex-col flex-items gap-2">
                                        <VoucherInput />
                                        <div className="flex items-center">
                                            <Alert
                                                message="Vouchers will be generated in a CSV file that you can download later."
                                                showIcon
                                            />
                                        </div>
                                        <Form.Item className="p-0" style={{ margin: '0' }}>
                                            <Button
                                                type="primary"
                                                loading={generatingVouchers}
                                                disabled={generatingVouchers}
                                                htmlType="submit"
                                            >
                                                <span>Generate</span>
                                            </Button>
                                        </Form.Item>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </div>,
                    },
                    {
                        label: (
                            <div className={"flex flex-row gap-0 items-center"}>
                                <Text className={classnames("uppercase", userRole !== 'FINANCE' && 'text-gray-300')}>Voucher Redemption History</Text>
                            </div>
                        ),
                        key: 'redemption_history',
                        disabled: userRole !== 'FINANCE',
                        children: (
                            <VoucherRedemptionPage />
                        ),
                    },
                ]}
            />
            <Modal {...confirmationModal} />
        </div>
    );
};

export default VouchersPage;
