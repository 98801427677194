import React, { useCallback, useState } from 'react';
import { Button, message } from 'antd';
import { Table } from 'components';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getLayersList } from 'services/message.service';
import useFilter from 'hooks/useFilter';
import ZoneFilter from './ZoneFilter';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import CreateLayerModal from './CreateLayerModal';
import useModal from 'hooks/useModal';
import DeleteLayerModal from './DeleteLayerModal';
import moment from 'moment';

const ZonePage = () => {
    const [data, setData] = useState([]);
    const [layerName, setLayerName] = useState<string | null>(null); // New state for layer name
    const [layerIdToDelete, setLayerIdToDelete] = useState<number | null>(null); // New state
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const navigate = useNavigate();
    const createLayerModal = useModal();
    const deleteLayerModal = useModal();

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        sort_key: '',
        sort_by: '',
        id: '',
        name: '',
    });
    const handleOnDeletetModal = useCallback(
        (id: number, name: string) => {
            setLayerIdToDelete(id);
            setLayerName(name);
            deleteLayerModal.show();
        },
        [deleteLayerModal],
    );

    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: 'View',
                                    key: '1',
                                    onClick: () => {
                                        navigate(`/zones/overview/${user.id}`);
                                    },
                                },
                                {
                                    label: 'Delete',
                                    key: '2',
                                    onClick: () => handleOnDeletetModal(user.id, user.name),
                                },
                            ],
                        }}
                    >
                        <Button type="ghost">
                            Action
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </>
            );
        },
        [navigate, handleOnDeletetModal],
    );

    const { request, loading } = useApi({
        api: getLayersList,
    });

    const columns = [
        {
            title: 'Layer ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.id}</Text>
                </div>
            ),
        },
        {
            title: 'Layer Name',
            id: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            id: 'description',
            dataIndex: 'description',
        },
        // {
        //     title: 'Zones',
        //     id: 'zones',
        //     dataIndex: 'zones',
        // },
        // {
        //     title: 'Created By',
        //     id: 'created_by',
        //     dataIndex: 'created_by',
        // },
        {
            title: 'Created At',
            id: 'created_at',
            dataIndex: 'created_at',
            render: (created_at: string) => moment.utc(created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A'),
        },
        {
            title: 'Updated At',
            id: 'updated_at',
            dataIndex: 'updated_at',
            render: (updated_at: string) => moment.utc(updated_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A'),
        },
        {
            title: 'Action',
            render: (user: any) => ColumnActions(user),
        },
    ];

    useMount(() => {
        fetchLayersList(requestState);
    });

    const fetchLayersList = useCallback(
        async (requestState: {}) => {
            try {
                request(requestState)
                    .then(result => {
                        let d = result.data;
                        if (result?.error == null) {
                            let layers = d.layers.map((c: any) => {
                                return {
                                    ...c,
                                    key: c.id,
                                };
                            });
                            setData(layers);
                            let pagination = d.pagination;
                            let p = {
                                ...pagination,
                                current: pagination.page,
                                defaultCurrent: 1,
                                pageSize: pagination.page_size,
                                total: pagination.total_count,
                            };
                            setPagination(p);
                        } else {
                            message.error("Error in fetching layers' list");
                        }
                    })
                    .catch((error: any) => {
                        message.error(error.message);
                    });
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;

            let sortKey = 'created_at';
            let sortBy = 'desc';
            if (order === 'ascend') {
                sortBy = 'asc';
            } else if (order === 'descend') {
                sortBy = 'desc';
            }
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: sortKey,
                sort_by: sortBy,
            });

            await fetchLayersList(requestState || {});
        },
        [fetchLayersList, modifyFilters],
    );

    const onFilterSubmit = async (filter: any) => {
        modifyFilters({
            id: filter.id,
            name: filter.name,
        });
        await fetchLayersList({
            ...requestState,
            page: 1,
            id: filter.id,
            name: filter.name,
        });
    };

    const handleOnShowModal = useCallback(() => {
        createLayerModal.show();
    }, [createLayerModal]);

    return (
        <ListLayout
            title="Layers"
            searchComponent={<ZoneFilter onFilterSubmit={onFilterSubmit} loading={loading} />}
            actionComponent={
                <div className="flex">
                    <div>
                        <Button type="primary" onClick={() => handleOnShowModal()}>
                            Create New Layer
                        </Button>
                    </div>
                </div>
            }
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                scroll={{ x: 1000 }} // fix horizontal
                onChange={onTableChange}
            />
            <CreateLayerModal
                modal={createLayerModal}
                onSuccess={() => {
                    fetchLayersList(requestState);
                }}
            />
            <DeleteLayerModal
                modal={deleteLayerModal}
                layerId={layerIdToDelete}
                name={layerName}
                onSuccess={() => {
                    fetchLayersList(requestState);
                }}
            />
        </ListLayout>
    );
};

export default ZonePage;
