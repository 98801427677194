import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button, Table } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import { adjustBalance, createBatchInstallment } from 'services/message.service';
import papa from 'papaparse';
import { json2csv } from 'json-2-csv';
import { useNavigate } from 'react-router-dom';
import { Alert, Tag, message, notification } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { downloadCSVFile } from '../../../services/file.service';
import classnames from 'classnames';
import { formatNumberToAmount } from 'services/number.service';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const BulkTopUpsDeductions = () => {
    const [tableData, setTableData]: any = useState([]);
    const [showDownloadBtn, setShowDownloadBtn] = useState(false);
    const [erroNumber, setErrorNumber] = useState(0);
    const [csv, setCsv] = useState('');
    const [successNumber, setSuccessNumber] = useState(0);
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }

    const {
        request: requestAdjustBalance,
        loading,
        result,
    } = useApi({
        api: adjustBalance,
    });

    const uploadCsvData = useCallback(
        async (csvResult: any[]) => {
            let errorRecords: any[] = [];
            let successRecords: any[] = [];
            let successNumber = 0;
            for (const adjustment of csvResult) {
                try {
                    if (adjustment?.reason?.length > 240) {
                        errorRecords.push({
                            driver_id: adjustment.driver_id,
                            wallet_type: adjustment.wallet_type,
                            reason: adjustment.reason,
                            direction: adjustment.direction,
                            amount: parseFloat(adjustment.amount),
                            status: 'FAILED',
                            failed_response: 'Provided reason should not exceed 240 characters.',
                        });
                    } else {
                        const res = await requestAdjustBalance({
                            body: {
                                driver_id: adjustment.driver_id,
                                wallet_type: adjustment.wallet_type,
                                direction: adjustment.direction,
                                amount: parseFloat(adjustment.amount),
                                reason: adjustment.reason
                            },
                        });
                        if (res.error) {
                            errorRecords.push({
                                driver_id: adjustment.driver_id,
                                wallet_type: adjustment.wallet_type,
                                reason: adjustment.reason,
                                direction: adjustment.direction,
                                amount: parseFloat(adjustment.amount),
                                status: 'FAILED',
                                failed_response: res.error.errorCode,
                            });
                        } else {
                            successRecords.push({
                                driver_id: adjustment.driver_id,
                                wallet_type: adjustment.wallet_type,
                                reason: adjustment.reason,
                                direction: adjustment.direction,
                                amount: parseFloat(adjustment.amount),
                                status: 'SUCCESS',
                            });
                            successNumber++;
                        }
                    }

                    
                } catch (error) {
                    console.error(error);
                }
            }
            if (errorRecords.length === 0) {
                notification.success({
                    message: 'Success',
                    description: 'Bulk Top-up/Deduction Successful',
                    duration: 3,
                });
                setTableData(successRecords);
            } else {
                handleFailedRecords(errorRecords, '');
            }
            setSuccessNumber(successNumber);
        },
        [requestAdjustBalance],
    );

    const handleFailedRecords = async (errorRecords: any[], common_err: string) => {
        try {
            const csvData = await json2csv(errorRecords);
            setCsv(csvData);
            setTableData(errorRecords);
            setErrorNumber(errorRecords.length);
            setShowDownloadBtn(true);
        } catch (e) {
            console.log(e);
        }
    };

    const downloadCSVData = () => {
        const csvData = String(csv);
        downloadCSVFile(csvData, 'failed_transactions');
    };

    const downloadTemplate = () => {
        let tempData = `driver_id,amount,reason,direction,wallet_type
11b19c68-f913-484f-823e-32e2468ca634,100,test,CREDIT,CREDIT
1d70cf4a-1179-4b52-ad65-d66adaf5a1d7,200,test,DEBIT,CASH
b0782bbb-06b7-474b-bf4f-853456b370af,20,test,CREDIT,CASH
2d05668f-9214-455c-91e7-f3380f3487e9,1000,test,DEBIT,CASH`;
        downloadCSVFile(tempData, 'bulk-topup-deduct-template-v1');
    };

    const columns = [
        {
            title: 'Driver ID',
            dataIndex: 'driver_id',
            key: 'driver_id',
        },
        {
            title: 'Amount',
            key: 'amount',

            render: (transaction: any) => (
                <div className={classnames(transaction?.direction == 'CREDIT' ? 'text-green-600' : 'text-red-600')}>
                    {`PHP ${formatNumberToAmount(transaction?.amount)}`}
                </div>
            ),
        },
        {
            title: 'Reason for Top-up or Deduction',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Direction',
            dataIndex: 'direction',
            key: 'direction',
        },
        {
            title: 'Wallet Type',
            dataIndex: 'wallet_type',
            key: 'wallet_type',
        },
        {
            title: 'Status',
            key: 'status',
            render: (transaction: any) => (
                <div className="flex">
                    {transaction.status == 'SUCCESS' ? (
                        <Tag color="green" className="uppercase">
                            {transaction.status}
                        </Tag>
                    ) : (
                        <Tag color="orange" className="uppercase">
                            {transaction.status}
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            title: 'Failed Response',
            key: 'failed_response',
            render: (transaction: any) => transaction?.failed_response,
        },
    ];

    const handleFile = (event: any) => {
        setShowDownloadBtn(false);
        papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (result: any) {
                const max_rows = 1000;
                if (result.data.length > max_rows) {
                    // Maximum of 1000 rows per upload
                    notification.error({
                        message: 'Error',
                        description: `Maximum of ${max_rows} Rows per upload`,
                        duration: 3,
                    });

                    return;
                }
                try {
                    let arrayObj = result.data.map((d: any) => {
                        return {
                            ...d,
                        };
                    });
                    uploadCsvData(arrayObj);
                } catch (err) {
                    message.error('Please upload a CSV file with a valid template.');
                }
            },
        });
    };

    const handleFileUpload = (event: any) => {
        (hiddenFileInput?.current as any)?.click();
    };

    return (
        <ListLayout title="Bulk Top-up and Deductions">
            <div className="flex justify-between">
                <Alert
                    showIcon
                    type="warning"
                    message="Please make sure you follow the correct format before uploading the
                    CSV file."
                />

                <div className="flex flex-row gap-1">
                    {hasActionAccess(PagesEnum.TOP_UP_AND_DEDUCTIONS) && <div>
                        <input
                            hidden
                            id="upload-file"
                            type="file"
                            accept=".csv"
                            name="file"
                            ref={hiddenFileInput}
                            onChange={handleFile}
                        ></input>
                        <Button type="primary" onClick={handleFileUpload} icon={<UploadOutlined />}>
                            Upload CSV
                        </Button>
                    </div>}
                    <Button type="ghost" target="_blank" onClick={() => downloadTemplate()} icon={<DownloadOutlined />}>
                        Download Template
                    </Button>
                </div>
            </div>
            {showDownloadBtn ? (
                <div style={{ border: '1px solid #ADB6B9' }} className="flex justify-between items-center m-2 p-4">
                    <div>
                        <ul className="text-sm font-bold p-0">
                            Upload .csv error
                            <li className="block font-medium text-base">{erroNumber} Number of rows have error </li>
                            <li className="block font-medium text-base">{successNumber} Number of rows have success</li>
                        </ul>
                    </div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                border: '1px solid #D9D9D9',
                                boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                            }}
                            className="bg-inherit px-4 py-2"
                            onClick={() => downloadCSVData()}
                            id="text-button"
                        >
                            Download Failed Rows
                        </button>
                    </div>
                </div>
            ) : null}
            <Table columns={columns} loading={loading} dataSource={tableData} />
        </ListLayout>
    );
};

export default BulkTopUpsDeductions;
