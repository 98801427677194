import { Alert, Collapse, notification, Select, Switch } from 'antd';
import { Button, Text, TextArea } from 'components/basic';
import Field from 'components/basic/Field/Field';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import React, { useCallback, useContext, useState } from 'react';
import { getCustomerDevices, updateCustomer } from 'services/message.service';
const { Option } = Select;
import moment from 'moment';
import DeviceInfoCard from 'components/pages/Drivers/DriverDetailed/DeviceInfoCard';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { Accessibility, getPermissions, PagesEnum } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';

const CustomerOverview = ({
    data,
    loading,
    id,
    fetchCustomer,
}: {
    data: any;
    loading: boolean | undefined;
    id: string;
    fetchCustomer: any;
}) => {
    const managerHubUrl = process.env.REACT_APP_MANAGER_HUB_URL;
    const auth0Env = process.env.REACT_APP_AUTH0_ENV;

    const [isServiceabilityEditable, setIsServiceabilityEditable] = useState(false);
    const [isAccountCardEditable, setIsAccountCardEditable] = useState(false);
    const [accountStatus, setAccountStatus] = useState<string>(data.account_status);
    const [accountStatusReason, setAccountStatusReason] = useState<string | undefined>();
    const [serviceZone, setServiceZone] = useState<string>(data.service_zone);
    const [customerGroups, setCustomerGroups] = useState<string[]>(
        data?.customer_groups?.map((group: any) => group.id) || [],
    );
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) || 
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }

    const {
        request: requestCustomerDevices,
        result: devices,
        loading: fetchingDevices,
    } = useApi({
        api: getCustomerDevices,
    });

    useMount(() => {
        fetchCustomersDevices();
    });

    const fetchCustomersDevices = useCallback(async () => {
        try {
            await requestCustomerDevices({ id });
        } catch (error) {
            throw error;
        }
    }, [requestCustomerDevices, id]);

    const handleSubmit = async () => {
        const res = await updateCustomer({
            id: id,
            body: {
                account_status: accountStatus,
                account_status_reason: accountStatusReason,
                customer_groups: customerGroups,
                service_zone: serviceZone,
            },
        });

        const { error } = res;
        if (!error) {
            notification.success({
                message: 'Success',
                description: 'Successfully Saved.',
                duration: 3,
            });
        } else {
            notification.error({
                message: 'Error',
                description: error.message,
                duration: 3,
            });
        }
        fetchCustomer(id);
    };

    const handleCancel = () => {
        setAccountStatusReason('');
        setAccountStatus(data.account_status);
        setCustomerGroups([]);
    };

    const customerGroupsDefaultValue = data.customer_groups?.map((group: any) => {
        return {
            label: group.group_name,
            value: group.id,
        };
    });

    const viewInManagerHub = (jalopy_id: number) => () => {
        window.open(`${managerHubUrl}/biker/detail/${jalopy_id}`, '_blank');
    };

    const viewInAuth0 = (authID: string) => () => {
        window.open(`https://manage.auth0.com/dashboard/jp/${auth0Env}/users/${authID}`, '_blank');
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex flex-col col-span-2 gap-4">
                {!data.first_name && !data.last_name && (
                    <Alert
                        message={
                            <div>
                                This user has not yet finished the registration process. This happens when the user has
                                provided a phone number but have not given their basic information yet.
                            </div>
                        }
                        type="warning"
                        showIcon
                    />
                )}

                <Alert
                    message={
                        <div>
                            Ability to update some fields will come soon! For the meantime,{' '}
                            <a href="https://app.clickup.com/14274986/v/fm/dkmda-12587" target="_blank">
                                request a ticket
                            </a>{' '}
                            when you need help updating details.
                        </div>
                    }
                    type="info"
                />

                <div className="bg-white rounded p-lg border border-dashed border-slate-200">
                    <div className="flex justify-between items-center">
                        <Text color="text-gray" className="uppercase">
                            Serviceability
                        </Text>
                        {isServiceabilityEditable ? (
                            <div className="flex gap-2">
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setIsServiceabilityEditable(false);
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button size="small" type="primary" onClick={handleSubmit}>
                                    Save
                                </Button>
                            </div>
                        ) : hasActionAccess(PagesEnum.CUSTOMERS) ? 
                            <Button size="small" onClick={() => setIsServiceabilityEditable(true)}>
                                Edit
                            </Button> : null
                        }
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-4 gap-x-6 gap-y-2">
                        <div>
                            <Text type="label">Domicile Service Zone</Text>
                            <Text fontWeight="font-semibold" color="text-black-secondary" className="mb-0">
                                {data.service_zone || '-'}
                            </Text>
                        </div>
                        <div>
                            <Text type="label">Customer Groups</Text>
                            {isServiceabilityEditable ? (
                                <Select
                                    className="w-full"
                                    mode="multiple"
                                    onChange={value => setCustomerGroups(value)}
                                    defaultValue={customerGroupsDefaultValue}
                                >
                                    <Option value="vip_list">VIP List</Option>
                                </Select>
                            ) : data.customer_groups ? (
                                data.customer_groups?.map((cg: any) => {
                                    return (
                                        <Text fontWeight="font-semibold" color="text-black-secondary" className="mb-0">
                                            {cg.group_name}
                                        </Text>
                                    );
                                })
                            ) : (
                                <span className="text-gray-300">No Customer Group Yet</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded p-lg border border-dashed border-slate-200">
                    <div className="flex justify-between items-center">
                        <Text color="text-gray" className="uppercase">
                            Account Information
                        </Text>
                        {isAccountCardEditable ? (
                            <div className="flex gap-2">
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setIsAccountCardEditable(false);
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button size="small" type="primary" onClick={handleSubmit}>
                                    Save
                                </Button>
                            </div>
                        ) : hasActionAccess(PagesEnum.CUSTOMERS) ? 
                            <Button
                                size="small"
                                onClick={() => {
                                    setIsAccountCardEditable(true);
                                    handleCancel();
                                }}
                            >
                                Edit
                            </Button> : null
                        }
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-4 gap-x-6 gap-y-2">
                        <div>
                            <Text type="label">Account Status</Text>
                            {isAccountCardEditable ? (
                                <div className="flex flex-col gap-2">
                                    <Select
                                        className="w-full"
                                        defaultValue={accountStatus}
                                        onChange={value => setAccountStatus(value)}
                                    >
                                        <Option value="ACTIVE">Active</Option>
                                        <Option value="DEACTIVATED">Deactivated</Option>
                                    </Select>
                                    <div>
                                        <Text type="label">Status Update Reason</Text>
                                        <TextArea
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                const { value } = event.target;
                                                setAccountStatusReason(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <span className="font-semibold">{data.account_status || '-'}</span>
                            )}
                        </div>

                        <Field
                            label="Terms Accepted At"
                            labelTooltip="This is the date when the customer accepted the terms and conditions through the app."
                            value={
                                data.terms_accepted_at
                                    ? moment.utc(data.terms_accepted_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-'
                            }
                        />

                        <Field
                            label={data.jalopy_id > 0 ? 'Account Migrated At' : 'Account Created At'}
                            value={
                                data.created_at
                                    ? moment.utc(data.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-'
                            }
                        />

                        <Field
                            label="Last Updated At"
                            value={
                                data.updated_at
                                    ? moment.utc(data.updated_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-'
                            }
                        />
                    </div>
                </div>

                <div className="bg-white rounded p-lg border border-dashed border-slate-200">
                    <Collapse className="card-collapse" bordered={false} expandIconPosition="right" ghost>
                        <CollapsePanel header={'Advanced Information for Developers'} key="1" className="p-0">
                            <div className="flex flex-row gap-2 mb-4">
                                {data.jalopy_id > 0 && (
                                    <Button onClick={viewInManagerHub(data.jalopy_id)}>View User in Manager Hub</Button>
                                )}
                                {data.authentication_id && (
                                    <Button onClick={viewInAuth0(data.authentication_id)}>View User in Auth0</Button>
                                )}
                                <Button onClick={viewInManagerHub(data.jalopy_id)}>View User in Appsflyer</Button>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 mb-4 gap-x-6 gap-y-2">
                                <Field label="MH User ID" value={data.jalopy_id} copyable />
                                <Field label="MH Firebase ID" value={data.jalopy_firebase_id} copyable />
                            </div>

                            <Field label="Internal ID" valueType="uuid" value={data.id} copyable />

                            <div className="mt-2">
                                <Alert
                                    message="Use Customer ID when communicating with our customers. Internal ID is used by our developers for troubleshooting purposes. "
                                    type="warning"
                                    showIcon
                                />
                            </div>
                        </CollapsePanel>
                    </Collapse>
                </div>
            </div>
            <DeviceInfoCard
                refreshList={fetchCustomersDevices}
                deviceData={devices?.data}
                loading={fetchingDevices}
                hasActivate={true}
            />
        </div>
    );
};

export default CustomerOverview;
