import React, { useCallback, useContext, useState } from 'react';
import Text from 'components/basic/Typography/Text';
import Field from 'components/basic/Field/Field';
import { CameraOutlined } from '@ant-design/icons';
import AvatarUpload from 'components/basic/AvatarUpload/AvatarUpload';
import { Button, Input, InputNumber, Select, notification } from 'antd';
import classnames from 'classnames';
import { updateCustomerInformation } from 'services/message.service';
import { Accessibility, getPermissions, PagesEnum } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';
const { Option } = Select;

const CustomerSummary = ({ data, page, expanded, refreshData }: any) => {
    const [isPersonalInformationEditable, setIsPersonalInformationEditable] = useState(false);
    const [firstName, setFirstName] = useState<string>(data.first_name);
    const [middleName, setMiddleName] = useState<string>(data.middle_name);
    const [lastName, setLastName] = useState<string>(data.last_name);
    const [genderValue, setGenderValue] = useState<string>(data.gender);
    const [weightValue, setWeightValue] = useState<number>(data.weight);

    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }
    const handleFirstNameChange = (event: any) => {
        setFirstName(event.target.value);
    };
    const handleMiddleNameChange = (event: any) => {
        setMiddleName(event.target.value);
    };
    const handleLastNameChange = (event: any) => {
        setLastName(event.target.value);
    };
    const handleWeightChange = (event: any) => {
        setWeightValue(event.target.value);
    };

    const handleSubmit = async () => {
        const res = await updateCustomerInformation({
            id: data.id,
            body: {
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                gender:genderValue,
                weight: weightValue,
            },
        });

        const { error } = res;
        if (!error) {
            notification.success({
                message: 'Success',
                description: 'Customer’s personal information successfully updated.',
                duration: 3,
            });
        } else {
            notification.error({
                message: 'Error',
                description: error.message,
                duration: 3,
            });
        }
        refreshData();
    };
    return (
        <div className={classnames('flex-1', expanded ? '' : 'flex gap-8 items-center')}>
            <div className={classnames('lg:flex items-center justify-between', expanded ? '' : 'flex')}>
                <div className={classnames('flex gap-4 flex-row items-center', expanded ? ' ' : '')}>
                    <div className="rounded-full w-12 h-12 relative">
                        {page === 'trip' ? (
                            <img src={data.avatar_url} className="rounded-full w-12 h-12 bg-gray-100" />
                        ) : (
                            <AvatarUpload
                                data={data}
                                userType="driver"
                                onAvatarChange={url => {
                                    data.avatar_url = url;
                                }}
                                onUploadFinish={refreshData}
                            />
                        )}
                    </div>

                    <Text size="text-lg" fontWeight="font-semibold" className="mb-0">
                        {data.first_name || '-'} {data.last_name || '-'}
                    </Text>
                </div>
            </div>

            <div
                className={classnames(
                    '',
                    expanded ? 'grid gap-x-6 gap-y-2 grid-cols-2 mt-4' : 'flex gap-6 items-center',
                )}
            >
                <Field label="Customer ID" value={data.public_id} copyable />
                <Field label="Mobile Number" value={data.phone_number} copyable />


            </div>
            <div className="flex justify-between items-center mt-4">
                <Text color="text-gray" className="uppercase">
                    Personal Information
                </Text>
                {isPersonalInformationEditable ? (
                    <div className="flex gap-2">
                        <Button
                            size="small"
                            onClick={() => {
                                setIsPersonalInformationEditable(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button size="small" type="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                ) : hasActionAccess(PagesEnum.CUSTOMERS) ?
                    <Button size="small" onClick={() => setIsPersonalInformationEditable(true)}>
                        Edit
                    </Button> : null
                }
            </div>
            <div className='mt-4'>
                    {isPersonalInformationEditable ? (
                        <div className="grid gap-x-6 gap-y-2 grid-cols-2">
                            <div>
                                <Text type="label">First Name</Text>
                                <Input
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                >
                                </Input>
                            </div>
                            <div>
                                <Text type="label">Middle Name</Text>
                                <Input
                                    value={middleName}
                                    onChange={handleMiddleNameChange}
                                >
                                </Input>
                            </div>
                            <div>
                                <Text type="label">Last Name</Text>
                                <Input
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                >
                                </Input>
                            </div>
                            <div>
                                <Text type="label">Gender</Text>
                                <Select
                                    className="w-full"
                                    defaultValue={genderValue}
                                    onChange={value => setGenderValue(value)}
                                >
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                    <Option value="Other">Others</Option>

                                </Select>
                            </div>
                            <div>
                                <Text type="label">Weight</Text>
                                <Input
                                    type='number'
                                    value={weightValue}
                                    onChange={handleWeightChange}
                                >
                                </Input>
                            </div>
                            <div>
                            <Field label="Email" value={data.email || '-'} className={expanded ? 'col-span-2' : ''} />
                            </div>
                        </div>
                    ) : (
                        <div className={classnames(
                            '',
                            expanded ? 'grid gap-x-6 gap-y-2 grid-cols-2 mt-4' : 'flex gap-6 items-center',
                        )}>
                            {expanded && (
                                <>
                                    <Field label="First Name" value={data.first_name || '-'} />
                                    <Field label="Middle Name" value={data.middle_name || '-'} />
                                    <Field label="Last Name" value={data.last_name || '-'} />
                                </>
                            )}
                            <Field label="Gender" value={data.gender || '-'} />
                            <Field label="Weight" value={data.weight ? `${data.weight}kg` : '-'} />
                            <Field label="Email" value={data.email || '-'} className={expanded ? 'col-span-2' : ''} />
                        </div>
                    )}
            </div>
            {page === 'trip' ? (
                <div className="flex mt-4">
                    <Button
                        className="w-full"
                        onClick={() => {
                            window.open(`/customers/${data.id}/overview`, '_blank');
                        }}
                    >
                        View Customer
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default CustomerSummary;
