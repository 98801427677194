import React, { useCallback, useContext, useState } from 'react';
import { Spin, Alert, Tabs, Skeleton } from 'antd';
import { Card } from 'components';
import { getDriver, updateDriver, updateDriverTnvStatus } from 'services/message.service';
import useMount from 'hooks/useMount';
import { useParams } from 'react-router-dom';
import Text from 'components/basic/Typography/Text';
import useApi from 'hooks/useApi';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import DriverOverview from './DriverOverview/DriverOverview';
import DriverVehicle from './DriverVehicle/DriverVehicle';
import DriverTripHistory from './DriverTripHistory/DriverTripHistory';
import DriverWallet from './DriverWallet/DriverWallet';
import DriverSummary from './DriverSummary';
import classnames from 'classnames';
import {
    CarOutlined,
    WalletOutlined,
    BulbOutlined,
    FileSearchOutlined,
} from '@ant-design/icons';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { useNavigate } from 'react-router-dom';

const DriverDetailedPage = ({ page = 'overview' }) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(page === 'overview');
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [currentPage, setCurrentPage] = useState(page);
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.VIEW) || 
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }

    let { id } = useParams();

    useMount(() => {
        fetchDriver(id + '');
    });

    const { request, result, loading } = useApi({
        api: getDriver,
    });

    const { request: requestUpdateDriver, loading: updating } = useApi({
        api: updateDriver,
    });

    const { request: updateTnvStatus, loading: updatingTnvStatus } = useApi({
        api: updateDriverTnvStatus,
    });

    const fetchDriver = useCallback(
        async (driverId: string) => {
            try {
                await request({ id: driverId || id });
            } catch (error) {
                throw error;
            }
        },
        [request, id]
    );

    if (loading) {
        return (
            <Card>
                <Skeleton />
            </Card>
        );
    }

    const data = result?.data || {};

    const onTnvStatusChange = (checked: boolean) => {
        updateTnvStatus({ checked, id });
    };

    return (
        <DetailContentLayout goBackLink="/drivers" setContainer={setContainer}>
            <div
                className={classnames(
                    'flex mt-4 transition-all ease-in-out',
                    expanded ? 'gap-4' : ''
                )}
            >
                <div
                    className={classnames(
                        'bg-white rounded border border-dashed border-slate-200 flex flex-col gap-2 transition-all ease-in-out',
                        expanded ? 'w-80 p-lg opacity-1' : 'w-0 p-0 opacity-0'
                    )}
                >
                    <DriverSummary
                        data={data}
                        page="driver"
                        expanded={expanded}
                        refreshData={() => request({ id })}
                    />
                </div>
                <div
                    className={classnames(
                        'flex-1 driver-profile-right transition-all ease-in-out',
                        expanded ? '' : ''
                    )}
                >
                    <div
                        className={classnames(
                            'bg-white rounded border border-dashed border-slate-200 flex flex-col gap-2 transition-all ease-in-out w-full',
                            expanded ? 'h-0 opacity-0' : 'h-auto opacity-1 p-sm mb-4'
                        )}
                    >
                        <DriverSummary
                            data={data}
                            page="driver"
                            expanded={false}
                            refreshData={() => request({ id })}
                        />
                    </div>

                    <Tabs
                        defaultActiveKey={currentPage}
                        className="gap-2"
                        tabBarGutter={50}
                        onChange={e => {
                            navigate(`/drivers/${id}/${e}`)
                            setCurrentPage(e);
                            setExpanded(e == 'overview');
                        }}
                        items={[
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <FileSearchOutlined />
                                        <Text className="uppercase">Overview</Text>
                                    </div>
                                ),
                                key: 'overview',
                                children: (
                                    <DriverOverview
                                        loading={updatingTnvStatus || updating}
                                        data={data}
                                        id={id + ''}
                                        updateRequest={requestUpdateDriver}
                                        onTnvStatusChange={onTnvStatusChange}
                                        fetchDriver={fetchDriver}
                                    />
                                ),
                            },
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <BulbOutlined />
                                        <Text className="uppercase">Trips</Text>
                                    </div>
                                ),
                                key: 'trip-history',
                                disabled: !hasActionAccess(PagesEnum.TRIPS),
                                children: <DriverTripHistory id={id} />,
                            },
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <WalletOutlined />
                                        <Text className="uppercase">Wallet</Text>
                                    </div>
                                ),
                                key: 'wallet',
                                disabled: !hasActionAccess(PagesEnum.WALLET_HISTORY),
                                children: <DriverWallet id={id} />,
                            },
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <CarOutlined />
                                        <Text className="uppercase">Vehicles</Text>
                                    </div>
                                ),
                                key: 'vehicles',
                                children: <DriverVehicle id={id} 
                                                         driversLicense={data?.license_number} 
                                                         expiryDate={data?.license_expiry_date}                                                         
                                                         fetchDriver={fetchDriver}
                                          />,
                            },
                        ]}
                    />
                </div>
            </div>
        </DetailContentLayout>
    );
};

export default DriverDetailedPage;
