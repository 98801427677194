import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { Button } from 'antd';
import { CustomNotification } from 'components/basic';
import { Input } from 'components/basic';

const mapContainerStyle = {
    width: '100%',
    height: '85vh',
};

const defaultCenter = {
    lat: 14.6091,
    lng: 121.0223,
};

const libraries: any[] = ['places'];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

interface MapComponentProps {
    onPolygonUpdate: (coords: google.maps.LatLng[] | null) => void;
    onPlaceNameUpdate: (name: string) => void;
    initialGeojson?: any;
    editableMode: 'full' | 'lastPolygonOnly';
}

const MapComponent: React.FC<MapComponentProps> = ({ editableMode, onPolygonUpdate, onPlaceNameUpdate, initialGeojson }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    const loadingError = loadError ? <div>Error loading maps</div> : null;
    const loadingIndicator = !isLoaded ? <div>Loading...</div> : null;

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [polygons, setPolygons] = useState<google.maps.Polygon[]>([]);
    const [isPolygonDrawn, setIsPolygonDrawn] = useState(false);
    const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | null>(null);
    const [mapKey, setMapKey] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [polygonPath, setPolygonPath] = useState<google.maps.LatLng[] | null>(null);
    const [mapZoom, setMapZoom] = useState(10);
    const selectedPlaceRef = useRef<google.maps.places.PlaceResult | null>(null);


    useEffect(() => {
        if (initialGeojson?.features && map) {
            const newPolygons: google.maps.Polygon[] = [];
            const bounds = new google.maps.LatLngBounds();

            initialGeojson.features.forEach((feature: any) => {
                const coords = feature.geometry.coordinates[0];
                const path = coords.map((coord: [number, number]) => new google.maps.LatLng(coord[1], coord[0]));
                const newPolygon = new google.maps.Polygon({
                    paths: path,
                    strokeColor: '#1890FF',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#91D5FF8F',
                    fillOpacity: 0.35,
                    editable: editableMode === 'full',
                });

                newPolygon.setMap(map);
                newPolygons.push(newPolygon);

                path.forEach((point: google.maps.LatLng | google.maps.LatLngLiteral) => bounds.extend(point));

                google.maps.event.addListener(newPolygon.getPath(), 'set_at', () => {
                    const updatedPath = newPolygon.getPath().getArray();
                    onPolygonUpdate(updatedPath);
                });

                google.maps.event.addListener(newPolygon.getPath(), 'insert_at', () => {
                    const updatedPath = newPolygon.getPath().getArray();
                    onPolygonUpdate(updatedPath);
                });

                google.maps.event.addListener(newPolygon.getPath(), 'remove_at', () => {
                    const updatedPath = newPolygon.getPath().getArray();
                    onPolygonUpdate(updatedPath);
                });
            });

            setPolygons(newPolygons);
            if (newPolygons.length > 0) {
                map.fitBounds(bounds);
            } else {
                map.setCenter(defaultCenter);
            }
        }
    }, [initialGeojson, map, editableMode, onPolygonUpdate]);

    const center = polygons.length > 0 && polygonPath && polygonPath.length > 0
        ? { lat: polygonPath[0].lat(), lng: polygonPath[0].lng() }
        : markerPosition
            ? { lat: markerPosition.lat(), lng: markerPosition.lng() }
            : defaultCenter;

    const onMapLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
        setAutocompleteService(new google.maps.places.AutocompleteService());
        setPlacesService(new google.maps.places.PlacesService(map));
        if ((!initialGeojson || initialGeojson.features.length === 0) && !markerPosition) {
            map.setCenter(defaultCenter);
        } else if (markerPosition) {
            map.setCenter(markerPosition);
            // map.setZoom(14);
        }
        map.addListener('click', (event: google.maps.MapMouseEvent) => {
            if (event.latLng) {
                if (!selectedPlaceRef.current) {
                    CustomNotification({
                        type: 'error',
                        message: 'Location Required',
                        description: 'Please select a location before drawing a polygon.',
                    });
                    return;
                }

                setPolygonPath(prev => {
                    const newPath = [...(prev || []), event.latLng].filter(
                        (point): point is google.maps.LatLng => point !== null,
                    );

                    if (newPath.length > 0) {
                        const newPolygon = new google.maps.Polygon({
                            paths: newPath,
                            strokeColor: '#1890FF',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#91D5FF8F',
                            fillOpacity: 0.35,
                            editable: editableMode === 'full' || editableMode === 'lastPolygonOnly' && polygons.length === 0,
                            draggable: false,
                        });

                        newPolygon.setMap(map);
                        setPolygons(prevPolygons => [...prevPolygons, newPolygon]);
                        setIsPolygonDrawn(true);
                        onPolygonUpdate(newPath);

                        google.maps.event.addListener(newPolygon.getPath(), 'set_at', () => {
                            const updatedPath = newPolygon.getPath().getArray();
                            onPolygonUpdate(updatedPath);
                        });

                        google.maps.event.addListener(newPolygon.getPath(), 'insert_at', () => {
                            const updatedPath = newPolygon.getPath().getArray();
                            onPolygonUpdate(updatedPath);
                        });
                    }
                    return newPath;
                });
            } else if (polygons) {
                CustomNotification({
                    type: 'error',
                    message: 'Polygon already exists!',
                    description: 'Please clear the current polygon before drawing a new one.',
                });
            }
        });
    }, [onPolygonUpdate, polygons, editableMode, initialGeojson, markerPosition]);

    const onPlaceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        setInputValue(input);
        if (input.trim() === '') {
            setPredictions([]);
        } else if (autocompleteService) {
            autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    setPredictions(predictions || []);
                }
            });
        }
    };


    const onSelectPlace = (placeId: string) => {
        if (placesService) {
            placesService.getDetails({ placeId }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.location) {
                    selectedPlaceRef.current = place;
                    setMarkerPosition(place.geometry.location);
                    setPredictions([]);
                    setInputValue(place.name || 'Unknown Place');

                    if (map) {
                        const location = place.geometry.location;
                        if (location) {
                            map.panTo(location);  // Center the map on the location
                            setMapZoom(14);  // Set the zoom level to focus on the location
                        }
                    }
                    onPlaceNameUpdate(place.name || 'Unknown Place');
                }
            });
        }
    };


    const handleSearch = () => {
        const input = inputValue;
        if (autocompleteService) {
            autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                    setPredictions(predictions);
                    const placeId = predictions[0].place_id;
                    onSelectPlace(placeId);
                } else {
                    CustomNotification({
                        type: 'error',
                        message: 'No results found',
                        description: 'Please enter a valid location.',
                    });
                }
            });
        }
    };

    if (loadingError) return loadingError;
    if (loadingIndicator) return loadingIndicator;

    const mapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };

    const clearPolygon = () => {
        setPolygons([]); // Clears the polygons array in the state
        setPolygonPath(null); // Clears the polygon path state
        setInputValue(''); // Resets the input value
        onPolygonUpdate(null); // Notifies parent that polygon has been cleared
        setIsPolygonDrawn(false); // Resets the drawing state
        setMapKey(prevKey => prevKey + 1); // Forces a re-render of the map
    };

    return (
        <div style={{ position: 'relative' }}>
            <GoogleMap
                key={mapKey}
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={mapZoom}
                options={mapOptions}
                onLoad={onMapLoad}
            >
                <Marker position={center} />
                {markerPosition && <Marker position={markerPosition} />}
            </GoogleMap>

            <div style={{ position: 'absolute', top: '-93px', left: '0px', zIndex: 1 }}>
                <div className="flex">
                    <Input
                        type="text"
                        placeholder="Search Location and add to zone"
                        style={{ width: '300px', padding: '5px' }}
                        className="rounded border"
                        value={inputValue}
                        onChange={onPlaceChanged}
                        onClick={() => {
                            const input = inputValue;
                            if (autocompleteService) {
                                autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                                        setPredictions(predictions || []);
                                    }
                                });
                            }
                        }}
                    />
                    <Button style={{ height: '32px' }} type="primary" onClick={handleSearch}>
                        Search
                    </Button>
                </div>
                {isPolygonDrawn && (
                    <Button
                        onClick={clearPolygon}
                        style={{
                            marginLeft: '0.5rem',
                            padding: '5px 15px',
                            height: 'auto',
                            position: 'absolute',
                            top: '120px',
                            left: '20px',
                            background: '#0084B0',
                            color: 'white',
                        }}
                    >
                        Clear Polygon
                    </Button>
                )}
                {predictions && predictions.length > 0 && inputValue.trim() !== '' && (
                    <ul className='bg-white mt-1.5 list-none p-1.5'>
                        {predictions.map((prediction) => (
                            <li
                                className='cursor-pointer'
                                key={prediction.place_id}
                                onClick={() => onSelectPlace(prediction.place_id)}
                            >
                                {prediction.description}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default MapComponent;
