import React, { FunctionComponent } from 'react';
import styles from './DashboardPage.module.scss';
import { ButtonProps, Divider } from 'antd';
import classnames from 'clsx';
import { Text } from 'components/basic';
import { useNavigate } from 'react-router-dom';

const DashboardPage: FunctionComponent<ButtonProps> = (props) => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col gap-10 mt-8">
            <div className="flex flex-row items-center gap-16">
                <div className={classnames('w-96 relative p-8')}>
                    <img
                        src="/images/dashboard-smoke.png"
                        alt="dashboard smoke"
                        className={classnames(styles.dashboard_smoke, 'animate-pulse')}
                    />

                    <img
                        src="/images/dashboard-biker.png"
                        alt="dashboard biker"
                        className={classnames(styles.dashboard_biker)}
                    />
                </div>
                <div className="flex-1 flex items-start flex-col">
                    <h1 className={classnames(styles.title)}>Tara na!</h1>
                    <p className={classnames(styles.subtitle)}>
                        Welcome to the Angkas Admin Portal
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-2 mt-8">
                <div className="flex gap-4">
                    <div
                        className="items-center cursor-pointer transition ease-in-out duration-100 hover:opacity-80"
                        onClick={() => navigate('/trips')}
                    >
                        <div className="rounded p-4">
                            <img src="/images/trips.png" className="w-full" />
                        </div>
                        <Text className="text-black-lighter text-center mt-md uppercase">
                            View Trips
                        </Text>
                    </div>
                    <div
                        className="items-center cursor-pointer transition ease-in-out duration-100 hover:opacity-80"
                        onClick={() => navigate('/drivers')}
                    >
                        <div className="rounded p-4">
                            <img src="/images/driver-list.png" className="w-full" />
                        </div>
                        <Text className="text-black-lighter text-center mt-md uppercase">
                            View Driver List
                        </Text>
                    </div>
                    <div
                        className="items-center cursor-pointer transition ease-in-out duration-100 hover:opacity-80"
                        onClick={() => navigate('/customers')}
                    >
                        <div className="rounded p-4">
                            <img src="/images/customer-list.png" className="w-full" />
                        </div>
                        <Text className="text-black-lighter text-center mt-md uppercase">
                            View Customer List
                        </Text>
                    </div>
                    <div
                        className="items-center cursor-pointer transition ease-in-out duration-100 hover:opacity-80"
                        onClick={() => navigate('/installments')}
                    >
                        <div className="rounded p-4">
                            <img src="/images/installment.png" className="w-full" />
                        </div>
                        <Text className="text-black-lighter text-center mt-md uppercase">
                            View Installments
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
