import React, { MouseEventHandler, ReactNode } from 'react';
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import classnames from 'classnames';
import styles from './Button.scss';

interface ButtonProps {}

const Button: React.FC<ButtonProps & AntButtonProps> = (props) => {
    return (
        <AntButton
            {...props}
            className={classnames(
                'h-auto focus:outline-none flex flex-col items-center align-center justify-center text-sm',
                {
                    [`px-md py-sm border rounded-lg border-solid`]:
                        props.type === 'primary',
                    // [`px-md py-sm border rounded border-solid text-blue bg-white border-gray-200`]:
                    //     props.type === 'secondary',
                    [`px-lg py-md border rounded-md border-solid bg-transparent`]:
                        props.danger,
                    [`px-sm py-xs sm:px-md py-sm text-blue bg-transparent border-0`]:
                        props.type === 'link',
                    [`px-md py-sm text-blue bg-transparent border rounded border-solid`]:
                        props.type === 'ghost',
                },
                {
                    [`${styles.primary}`]: props.type === 'primary',
                    // [`${styles.secondary}`]: props.type === 'secondary',
                    [`${styles.danger}`]: props.danger,
                    [`${styles.link}`]: props.type === 'link',
                    [`${styles.ghost}`]: props.type === 'ghost',
                },
                styles.button,
                props.className
            )}
        >
            {props.children}
        </AntButton>
    );
};

export default Button;
